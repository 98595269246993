import React from 'react';
import { FormattedMessage } from 'react-intl';

import { List, makeStyles, Toolbar } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import Button from './../material-kit-react/CustomButtons/Button.jsx';
import headerLinksStyle from './../material-kit-react/Styles/components/headerLinksStyle.jsx';
import SwitchToEnglish from './../SwitchToEnglish';
import { createTheme, ThemeProvider } from '@mui/material/styles';

var imgLogo =require('./../../../../../public/sciendure-logo-s.png')


const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});


function HeaderLogo({ ...props }) {
  return (
    <ThemeProvider theme={theme}>
    <Toolbar>
      <a href={'/'} title="Sciendure" >
        <Avatar sx={{ width: 55, height: 55 }} alt="Sciendure" variant="square" src={imgLogo} />
      </a>
      <Typography sx={{ color: 'primary.main' }} variant="h4" style={{marginLeft:15}}>
        Sciendure
      </Typography>
    </Toolbar>
    </ThemeProvider>
  );
}

export default withStyles(headerLinksStyle)(HeaderLogo);
