import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, BrowserRouter as Router } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';

import Button  from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';


// import Button from './../material-kit-react/CustomButtons/Button.jsx';
import headerLinksStyle from './../material-kit-react/Styles/components/headerLinksStyle.jsx';
import SwitchToEnglish from './../SwitchToEnglish';

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
     light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});


function HeaderLinks({ ...props }) {
  const { classes } = props;
  return ( 
    <ThemeProvider theme={theme}>
    <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={() => props.changePage("login")}>Login</Button>
          <Button variant="outlined" onClick={() => props.changePage("register")}>Register</Button>
    </Stack> 
    </ThemeProvider>     
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
