import React, { Component, Fragment, forwardRef, useState, useEffect } from 'react'
import $ from 'jquery'; 
import Helmet from 'react-helmet';
import config from 'react-global-configuration';

import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  routerLink,
  useParams,
  useRouteMatch
} from "react-router-dom";

import { Form } from 'react-final-form';
import * as Yup from 'yup';

import {
  string,
  number,
  boolean,
  bool,
  date
} from 'yup';

import {
  Checkboxes,
  Radios,
  Select,
  DatePicker,
  TextField,
  makeValidate,
  showErrorOnBlur
} from 'mui-rff';

import {
  CssBaseline,
  CircularProgress,
  Divider,
  Snackbar,
} from '@material-ui/core';

import { createTheme, ThemeProvider } from '@mui/material/styles';


import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel'; 

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import MuiAlert, { AlertProps } from '@mui/material/Alert';

import 'date-fns';
import { parseISO, format } from 'date-fns';
import { convertToLocalTime } from 'date-fns-timezone';
import differenceInYears from "date-fns/differenceInYears";
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Header from './Header';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy, faPauseCircle } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles';

import axios from "axios";
import { passCsrfToken } from '../util/helpers'

import moment from 'moment';

//var imgLogo =require('./../../../../public/sciendure-logo-s.png')

import imgConnectWithGarmin from './../../assets/images/Garmin_Connect.png';

//import 'channels'
//import WebNotificationsChannel from 'channels/webnotifications_channel'
import consumer from './../channels/consumer'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//
const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

const schema = Yup.object().shape({
  birthday: Yup.date()
  .typeError('Please enter a valid date')
  .required()
  .test("birthday", "You must be over 18", function (value) {
                return differenceInYears(new Date(), new Date(value)) >= 18;
              }),
  email: Yup.string().email().required(),
  firstname: Yup.string('Please enter your firstname')
    .typeError('Please enter your first name')
    .required('Please enter your first name')
    .max(50,'Please not more than 50 characters'),
  lastname: Yup.string('Please enter your last name')
    .required('Please enter your last name')
    .typeError('Please enter your last name')
    .max(50,'Please not more than 50 characters'),
  athleteSex: Yup.string().required().oneOf(['M','F','E','N']),
  athleteWeight: Yup.number('Please enter a number').nullable()
    .typeError('Please enter a number')
    .test("athleteWeight", "Please enter a positive number", function (value) {
      return value>0;
    }),
  location: Yup.string().max(150)
});


const validate = makeValidate(schema);



const useStyles = makeStyles((theme) => ({
  		root: {
    		width: '100%',
    		'& > * + *': {
      		marginTop: theme.spacing(2),
    		},
  		},
	}));

//

function GarminConn( props ) {

  if (props.status == false) {
    return(
      <Grid item xs={3} >
          <Link onClick={props.connLink} variant="body1" style={{cursor:'pointer'}}>
            <div>  Connect with Garmin  </div>
          </Link>
          <Typography>
            Automatic uploads of your activities
          </Typography>        
        </Grid>
      )
  }
  else if (props.status == true) {
    return(
      <Grid item xs={3} >
          <Typography>
            You are connected
          </Typography>
          <Link onClick={props.disconnLink} variant="body1" style={{cursor:'pointer'}}>
            <div>  Disconnect  </div>
          </Link>        
        </Grid>
      )
  }
}


class Settings extends React.Component {
	constructor(props) {    
		super(props);    
		this.state = {   
			userData: null,
      garmin: false,
      authWindow: null,
      alertTextGarmin: null,
      openGarmin: false,
      severityGarmin: "success"
		};
	}


componentDidMount() {
   this.getUser();
   passCsrfToken(document, axios);
   consumer.subscriptions.create('WebnotificationsChannel', {
     connected() {  },
     disconnected() {  },
     received: data => {
        if (data.body == 'confirmed') { 
         this.setState({
           garmin: true
         });
         const authWindow = this.state.authWindow;
         authWindow.close();
         this.setState({
           authWindow: null
         });
         this.setState({ alertTextGarmin: "Successfully connected to Garmin. Data are syncing." })
         this.setState({ openGarmin: true, severityGarmin: "success" })
        }
        else if (data.body == 'failed') {
          const authWindow = this.state.authWindow;
          authWindow.close();
          this.setState({
            authWindow: null
          });
          this.setState({ alertTextGarmin: "Connection to Garmin failed. You might have cancelled it, or you did not agree to conditions." })
          this.setState({ openGarmin: true, severityGarmin: "warning"})
        }
        else if (data.body == 'no_permission') {
          const authWindow = this.state.authWindow;
          authWindow.close();
          this.setState({
            authWindow: null
          });
          this.setState({ alertTextGarmin: "You have not given the required permissions." })
          this.setState({ openGarmin: true,  severityGarmin: "warning" })
        }
     }
   });
};

componentWillUnmount() {
    consumer.disconnect()
  };

getUser() {
    const { match: { params } } = this.props;
    axios 
        .get(`/api/v1/athletes/${params.id}`)
        .then(response => response.data) 
        .then(data => {
          this.setState({ userData: data.userData });
          if (data.userData.garminConnected == true) {
            this.setState({
              garmin: true
            });
          }
        })
        .catch(error => {
            this.setState({redirect: true});
            console.log(error);
        });
}

//

startAuth() {

  const width = 500,
        height = 1000
  const left = (window.innerWidth / 2) - (width / 2)
  const top = (window.innerHeight / 2) - (height / 2)
  const url = `/garmin_authorizations` 
  
  return window.open(url, '', // Open window to server side
         `toolbar=no, location=no, directories=no, status=no, menubar=no, 
          scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
          height=${height}, top=${top}, left=${left}`
  )

}

render() {
    const { match: { params } } = this.props;

    const { userData } = this.state;

    let userId;
    let athleteName;
    let firstname;
    let lastname;
    let imageUrl;
    let email;
    let isPublic;
    let units;
    let location;
    let athleteSex;
    let athleteWeight;
    let totalRuns;
    let lastActivity;
    let followers;
    let friends;
    let created;
    let weightUnit;
    let weightFactor;
    let scheduledEvents;
    let scheduledUploads;
    let birthday;
    if (userData !== null) {
    	userId = userData.userId
        firstname = userData.firstname
        lastname = userData.lastname
        athleteName = userData.fullname
        imageUrl = userData.image_url
        email = userData.email
        isPublic = userData.public
        units = userData.units
        if (units == 'km') {weightUnit = 'kg'; weightFactor=1;}
        	else if (units == 'mile') {weightUnit = 'lb'; weightFactor=2.2046226;}
        location = userData.location
        athleteSex = userData.sex
        athleteWeight = (userData.weight * weightFactor).toFixed(1)
        totalRuns = userData.total_runs
        lastActivity = userData.last_activity

        followers = userData.followers
        friends = userData.friends
        created = userData.created
        scheduledEvents = userData.scheduledEvents
        	if (scheduledEvents > 0) {scheduledEvents = 'Yes -- we are working on it...'}
        		else {scheduledEvents = 'No'}
        scheduledUploads = userData.scheduledUploads
    	if ( userData.birthday ) { birthday = userData.birthday }
    		else { birthday = ''}
    }

    //

    const formatDate = (date) => {
    
      // Get the timezone from browser using native methods
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateTmp = Date.parse(date.toLocaleString());

      const localDate = convertToLocalTime(dateTmp, {
        timeZone: timezone,
      });

      return format(localDate, 'yyyy-MM-dd');
    
    };

    

    const initialValues = { 
            athlete: userId,
            firstname: firstname,
            lastname: lastname, 
            location: location,
            email: email, 
            isPublic: isPublic, 
            units: units,
            athleteSex: athleteSex,
            athleteWeight: athleteWeight,
            birthday: parseISO(birthday),
    };
  
   

    const formFields = [
  	{
    size: 3,
    field: (
      <TextField
        variant="standard"
        fullWidth
        label="First Name"
        name="firstname"
        margin="none"
      />
    ),
 	},
  {
    size: 3,
    field: (
      <TextField
        variant="standard"
        fullWidth
        label="Last Name"
        name="lastname"
        margin="none"
      />
    ),
  },
 	{
    size: 2,
    field: (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        fullWidth
        name="birthday"
        margin="none"
        label="Birthday"
        format={'MM/dd/yyyy'}
        required={true}       
        TextFieldProps={{ variant: 'standard' }}
        showError={showErrorOnBlur}
      />
    </LocalizationProvider>
    ),
  	},
    {
    size: 2,
    field: (
        <Select
          name="athleteSex"
          label="Gender"
          id="athleteSex"
          required={true}
        >
          <MenuItem value={'M'}>Man</MenuItem>
          <MenuItem value={'F'}>Women</MenuItem>
          <MenuItem value={'E'}>Prefer not to say</MenuItem>
          <MenuItem value={'N'}>Non-binary</MenuItem>
        </Select>
    )  
    },
 	 {
    size: 2,
    field: (
      <TextField
        variant="standard"
        fullWidth
        label={`Weight [${weightUnit}]`}
        name="athleteWeight"
        id="athleteWeight"
        margin="none"
      />
    ),
 	},
 	{
    size: 6,
    field: (
      <TextField
        variant="standard"
        fullWidth
        label="Location: City (State Country)"
        name="location"
        id ="location"
        margin="none"
      />
    ),
 	},
  	{
    size: 6,
    field: (
      <TextField
        variant="standard"
        fullWidth
        label="Email"
        name="email"
        margin="none"
        showError={showErrorOnBlur}
      />
    ),
  	},
 	{
    size: 12,
    field: (
      <Checkboxes
        name="isPublic"
        formControlProps={{ margin: 'none' }}
        data={{ label: `Public Timeline: share the link https://sciendure.com/timeline/${userId}` }}
      />
    ),
  	},
 	{
    size: 12,
    field: (
      <Radios
        label="Measurement Units"
        name="units"
        formControlProps={{ margin: 'none' }}
        radioGroupProps={{ row: true }}
        data={[
          { label: 'km & kg', value: 'km' },
          { label: 'mi & lb', value: 'mile' },
        ]}
        required={true}
      />
    ),
  	},
	];

	
  	
//

	const FormWithToasts = ({initialValues,formFields}) => {

	const classes = useStyles();
  		
	const [open, setOpen] = React.useState(false);	

	const [alertText, setAlertText] = React.useState("Saved successfully!");	

	const handleClose = (event, reason) => {
    	if (reason === 'clickaway') {
      		return;
    	}

    	setOpen(false);
    	window.location.reload(false);
  	};	
  //
    
	const onSubmit = async values => {
    var that = this;
    if (values.email != initialValues.email) {
      var data = { user: {
        email: values.email
        },
        turbo: false 
      };
      $.ajaxSetup({
        headers:
        { 'X-CSRF-TOKEN': $('meta[name=”csrf-token”]').attr('content') }
      });
      $.ajax({
      type: "PUT",
      url: "/users",
      dataType: 'json',
      data: data,
      error: function (xhr) {
        var error = $.parseJSON(xhr.responseText).error
        console.error('error code: ' + xhr.status)
        console.error('error msg: ' + error)
      },
      success: function (res) {
         setAlertText("Saved successfully! You will receive a message at your new Email for confirmation.");
      }
      });
    }
    values.birthday = formatDate(values.birthday)
    values.athleteWeight = (values.athleteWeight / weightFactor).toFixed(1);
    axios 
      .post('/api/v1/athletes/',values)
      .then(response => {
        	//if (values.email != initialValues.email) {
				  //setAlertText("Saved successfully! Since you have edited your Email you will receive a message at your new Email for confirmation.");
			    //} 
        setOpen(true);
          //console.log(response);
        	//console.log(response.data);
            })
      .catch((error)=>console.error(error));
	};



	return(
    <ThemeProvider theme={theme}>
		<div>
		<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        	<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          		{alertText}
        	</Alert>
    </Snackbar>
		<Form
        	onSubmit={onSubmit}
        	initialValues={initialValues}
        	validate={validate} >
        	{({ handleSubmit, form, submitting, pristine, values }) => (
          	<form onSubmit={handleSubmit} noValidate>
            	<Paper style={{ padding: 16 }}>
              		<Grid container alignItems="flex-start" spacing={2}>
                	{formFields.map((item, idx) => (
                  	<Grid item xs={item.size} key={idx}>
                    	{item.field}
                  	</Grid>
                	))}
                	<Grid item style={{ marginTop: 16 }}>
                  	<Button
                    type="button"
                    variant="contained"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                  	>
                    Reset
                  	</Button>
                	</Grid>
                	<Grid item style={{ marginTop: 16 }}>
                  	<Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  	>
                    Submit
                  	</Button>
                	</Grid>
             		</Grid>
            	</Paper>
          	</form>
          	)}
      	</Form>
      	</div>
        </ThemeProvider>
	);

	};

  //

  const handleConnectGarmin = () => {
  
      const authWindow = this.startAuth();
      
      this.setState({authWindow: authWindow});

    };

  const handleDisconnectGarmin = () => {
      
      $.ajax({
      type: "DELETE",
      url: "/garmin_authorizations/destroy",
      error: function (xhr) {
        var error = $.parseJSON(xhr.responseText).error
        console.error('error code: ' + xhr.status)
        console.error('error msg: ' + error)
      },
      success: function (xhr) {
        this.setState({ alertTextGarmin: "Successfully disconnected from Garmin. Syncing will stop." })
        this.setState({ openGarmin: true,  severityGarmin: "success" })
        this.setState({ garmin: false })
      }.bind(this)
      });

  };

  const handleCloseGarmin = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }

      this.setState({openGarmin: false});
      this.setState({ alertTextGarmin: null,  severityGarmin: "success" })
    
  };  

    return (
    	<Fragment>
      <ThemeProvider theme={theme}>
        <Snackbar open={this.state.openGarmin} autoHideDuration={6000} onClose={handleCloseGarmin}>
          <Alert onClose={handleCloseGarmin} severity={this.state.severityGarmin} sx={{ width: '100%' }}>
              {this.state.alertTextGarmin}
          </Alert>
        </Snackbar>
        <div>
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap" rel="stylesheet" /> 
        </div>

        <Header userId = {params.id} userName = {athleteName} imageUrl = {imageUrl} />
        <div style={{marginTop: 80, marginLeft:20, marginRight:20}}>
        
    	<Grid 
  			container item xs={12}
  			direction="row"
  			justifyContent="flex-start"
  			alignItems="flex-start"
		>

		<div>
        <Typography variant="h5" gutterBottom>
			My Profile
    	</Typography>
    	<Typography variant="body1">
    		Please note that if you change your email, you need to confirm your new email before it will be updated and can be used.
    	</Typography>
    	</div>
	
		
    	<FormWithToasts initialValues={initialValues} formFields={formFields} />
      	

      	<Box mt={3}>
      	<Typography variant="h5" gutterBottom>
			My Data
    	</Typography>
    	</Box>

    	<Box width={1} mb={3}>
    	<Paper style={{ padding: 16 }}>
            <Grid container alignItems="flex-start" spacing={2}>
            	<Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		Sciendure member since: 
                	</Typography> 	
                </Grid>
                <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		{moment(created).format('MMMM Do YYYY')}
                	</Typography> 	
                </Grid>
                <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		Number of runs uploaded: 
                	</Typography> 	
                </Grid>
                <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		{totalRuns}
                	</Typography> 	
                </Grid>
            
               {/* } <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		Link to last activity retrieved:
                	</Typography> 	
                </Grid>
                <Grid item xs={3} >   	
  					<Link href={`https://www.strava.com/activities/${lastActivity}`} target="_blank" rel="noreferrer" variant="body1">
    					<div> {lastActivity} </div>
  					</Link>
                </Grid>
                <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		Number of scheduled uploads: 
                	</Typography> 	
                </Grid>
                <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		{scheduledUploads}
                	</Typography> 	
                </Grid>
                <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		Unprocessed Strava events: 
                	</Typography> 	
                </Grid>
                <Grid item xs={3} >
                	<Typography variant="body1" noWrap>
                		{scheduledEvents}
                	</Typography> 	
                </Grid> */}
            </Grid>
        </Paper>
        </Box>
        

      <Box mt={3}>
        <Typography variant="h5" gutterBottom>
        Connections
        </Typography>
      </Box>
      <Box width={1} mb={3}>
      <Paper style={{ padding: 16 }}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={3} >
            <img alt="Connect With Garmin" src={imgConnectWithGarmin} />
          </Grid>
          <GarminConn status={this.state.garmin} connLink={handleConnectGarmin} disconnLink={handleDisconnectGarmin}/>
        </Grid>
      </Paper>
      </Box>

		  </Grid>
    	</div>
      </ThemeProvider>
    	</Fragment>


    );

  }

}




//
//const onSubmit = async values => {
//  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
//  await sleep(300);
//  window.alert(JSON.stringify(values, 0, 2));
//};

  //<Link href={`/garmin_authorizations`} target="_blank" rel="noreferrer" variant="body1">
          //    <div>  Connect with Garmin  </div>
          //</Link>



export default Settings;






