import React, { Fragment } from 'react';
import config from 'react-global-configuration';
import { FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';

import { Typography, Button } from '@material-ui/core';
import imgBackground from './../assets/img/main-bg.jpg';
import HeaderLinks from './Components/ErrorPage/HeaderLinks';
import HeaderLogo from './Components/HomePage/HeaderLogo';
import LandingPageStyle from './Components/LandingPageStyle.jsx';
import GridContainer from './Components/material-kit-react/Grid/GridContainer.jsx';
import GridItem from './Components/material-kit-react/Grid/GridItem.jsx';
import Header from './Components/material-kit-react/Header/Header.jsx';
import Parallax from './Components/material-kit-react/Parallax/Parallax.jsx';
import ButtonConnectWithStrava from "./Components/ButtonConnectWithStrava";

class ErrorPage extends React.Component {
  render() {
    const { classes, errorCode, ...rest } = this.props;

    const ColorButton = withStyles((theme) => ({
      root: {
        color: "#FFFFFF",
        backgroundColor: "#ad123c",
        '&:hover': {
        backgroundColor: "#730d28",
        },
    },
    }))(Button);

    let buttons;

    // if (errorCode === "401") {
    //   buttons = <ButtonConnectWithStrava />;
    // } else {
      buttons = <div>
        <ColorButton variant="contained" href="/" >
          <FormattedMessage id="errors.goToHomePage" />
        </ColorButton>
      </div>;
    // }

    return (
      <Fragment>
      <div>
        <Header
          color="transparent"
          leftLinks={<HeaderLogo />}
          fixed
          {...rest}
        />
        <Parallax filter image={imgBackground} className="parallax">
          <div className={`${classes.container} headline`}>
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <Typography variant="h3" gutterBottom>
                  <FormattedMessage id={`errors.${errorCode}.headline`} />
                </Typography>
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id={`errors.${errorCode}.descriptionLine1`} />
                </Typography>
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id={`errors.${errorCode}.descriptionLine2`} />
                </Typography>
                <br />
                {buttons}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </div>
      </Fragment>
    );
  }
}

export default withStyles(LandingPageStyle)(ErrorPage);
