//import 'core-js/es6/map';
//import 'core-js/es6/set';

import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import config from 'react-global-configuration';
import configFile from './config';

import { FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import imgBackground from './../assets/img/main-bg.jpg';
import Footer from './Components/HomePage/Footer';
import HeaderLinks from './Components/HomePage/HeaderLinks';
import HeaderLogo from './Components/HomePage/HeaderLogo';
import SectionDemo from './Components/HomePage/SectionDemo';
import SectionGuarantees from './Components/HomePage/SectionGuarantees';
import SectionFeatures from './Components/HomePage/SectionFeatures';
import SectionTeam from './Components/HomePage/SectionTeam';
import LandingPageStyle from './Components/LandingPageStyle.jsx';
import GridContainer from './Components/material-kit-react/Grid/GridContainer.jsx';
import GridItem from './Components/material-kit-react/Grid/GridItem.jsx';
import Header from './Components/material-kit-react/Header/Header.jsx';
import Parallax from './Components/material-kit-react/Parallax/Parallax.jsx';
import { title } from './Components/material-kit-react/Styles/material-kit-react.jsx';
import ButtonConnectWithStrava from "./Components/ButtonConnectWithStrava";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FlashState from "./FlashState";

config.set(configFile);


const dashboardRoutes = [];

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount() {
    this.setState({ message: FlashState.get('message') });
   }

  render() {
    const { classes, ...rest } = this.props;
    const redirectUri = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`;
    const authUrl =
      'https://www.strava.com/oauth/authorize?client_id=' +
      config.get('app.stravaApiClientId') +
      '&response_type=code&redirect_uri=' +
      redirectUri +
      config.get('app.redirectUriPath') +
      '&approval_prompt=auto&scope=read_all,profile:read_all,activity:read_all';


    return (
      <Fragment>
      <ThemeProvider theme={theme}>
      <div>
        <Header
          message={this.state.message}
          color="transparent"
          routes={dashboardRoutes}
          leftLinks={<HeaderLogo />}
          rightLinks={<HeaderLinks changePage={this.props.changePage} />}
          fixed
          changeColorOnScroll={{
            height: 160,
            color: 'white',
          }}
          {...rest}
        >
        </Header>
        <Parallax filter image={imgBackground} className="parallax">  
          <div className={`${classes.container} headline`}>
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <Typography variant="h3" gutterBottom>
                  <FormattedMessage id="home.parallax.headline" />
                </Typography>
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id="home.parallax.descriptionLine1" />
                </Typography>
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id="home.parallax.descriptionLine2" />
                </Typography>
                {/* <br />
                <ButtonConnectWithStrava /> */}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionFeatures />
            {/* <SectionDemo />
            <SectionGuarantees />
            <SectionTeam /> */}
          </div>
        </div>
        <Footer />
      </div>
      </ThemeProvider>
      </Fragment>
    );
  }
}



export default withStyles(LandingPageStyle)(Home);
