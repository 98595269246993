import * as React from 'react';
import { Component, Fragment, forwardRef, useState, useEffect, useCallback } from 'react';
import $ from 'jquery'; 
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import imgBackground from './../assets/img/main-bg.jpg';

import { Form } from 'react-final-form';
import * as Yup from 'yup';

import {
  TextField,
  makeValidate,
  showErrorOnBlur
} from 'mui-rff';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import FlashState from './FlashState';

var imgLogo =require('./../../../public/sciendure-logo-s.png')
var qs = require('qs');

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://sciendure.com/">
        sciendure.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//

function AlertToasts( props ) {

      const [open, setOpen] = React.useState(false);  
      const [alertText, setAlertText] = React.useState("");

      useEffect(() => {

      const setMessage = ( msg ) => {

        if (props.show == true ) {
          setAlertText( msg ); 
          setOpen(true);
        }

      }

      setMessage( props.errorMessage );

      }, [ props.show ]);

    return(
    <div>
      <Snackbar open={open} anchorOrigin={{ vertical: 'top' , horizontal: 'right'}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          {alertText}
        </Alert>
      </Snackbar>  
    </div>
    )

};

//

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

const schema = Yup
    .object()
    .shape({
      password: Yup.string()
        .required('Password is required.')
        .min(6, 'Password must be at least 6 characters')
        .max(40, 'Password must not exceed 40 characters'),
      password_confirmation: Yup.string()
        .required('Password is required.')
        .min(6, 'Password must be at least 6 characters')
        .max(40, 'Password must not exceed 40 characters'),       
    });

const validate = makeValidate(schema);


class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetUnsuccessful: false,
      errorMessage: null,
      navigate: false,
      userId: null
    };

  }



  handleReset = ( values ) => {
  var that = this;
  var data = { user: {
   password: values.password,
   password_confirmation: values.password_confirmation,
   reset_password_token: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).reset_password_token
   },
   turbo: false 
  };
  $.ajaxSetup({
    headers:
    { 'X-CSRF-TOKEN': $('meta[name=”csrf-token”]').attr('content') }
  });
  $.ajax({
   type: "PUT",
   url: "/users/password",
   dataType: 'json',
   data: data,
   error: function (xhr) {
    that.setState({
      resetUnsuccessful: true
    });
    var error = $.parseJSON(xhr.responseText).error
    if ( error == 'Reset password token is invalid' ) 
      { that.setState({ errorMessage: 'The provided token to reset the password is invalid. Please request a new link from "Forgot Password?".' }) }
    else 
      { that.setState({ errorMessage: error}) }
    console.log('error code: ' + xhr.status)
    console.log('error msg: ' + error)
    },
   success: function (res) {
      that.props.changePage("home");
      FlashState.set('message', 'Your password has been changed successfully. You can now login with the new password.');
    }
  });
  };
 



render() {

  const formFields = [
    {
    size: 8,
    field: (    
      <TextField
        margin="normal"
        required={true}
        name="password"
        label="Choose Password"
        type="password"
        id="password"
      />
    )
    },
    {
    size: 8,
    field: (    
      <TextField
        margin="normal"
        required={true}
        name="password_confirmation"
        label="Verify Password"
        type="password"
        id="password_confirmation"
      />
    )
    },
    ]

  return (
  <Fragment>
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${imgBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        <Stack sx={{ m: 6 }} spacing={2} direction="row">
        <a onClick={() => this.props.changePage("home")}  style={{cursor:'pointer'}}>
        <Avatar variant="square" src={imgLogo} sx={{ width: 70, height: 70 }}/>
        </a>
        <Typography variant="h2" component="h1" sx={{ color: 'primary.main' }} >
        Sciendure
        </Typography>
        </Stack>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <a onClick={() => this.props.changePage("home")}  style={{cursor:'pointer'}} >
            <Avatar sx={{ m: 1 }} variant="square" src={imgLogo} sx={{ width: 70, height: 70 }}/>
            </a>
            <Typography component="h1" variant="h5">
              Reset Your Password
            </Typography>
            <Typography component="h6" align="center">
              Create a new password for your account. 
            </Typography>
            <AlertToasts show={this.state.resetUnsuccessful} errorMessage={this.state.errorMessage} />
            <Box style={{ marginTop: 30}} >
            <Form 
              onSubmit={this.handleReset}
              validate={validate} >
                {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                  {formFields.map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                  <Grid container justifyContent="center" alignItems="center" >
                  <Grid item style={{ marginTop: 16 }} >
                    <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    sx={{ mt: 3, mb: 2 }}
                    >
                    Reset Password
                    </Button>
                  </Grid>
                  </Grid>
                </Grid>
                </form>
              )}
            </Form>
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    </Fragment>
  )};
}

export default withRouter(ResetPassword);

