import React, { Component, Fragment, forwardRef, useState, useEffect, useCallback } from 'react'
//
import Helmet from 'react-helmet';
import config from 'react-global-configuration';
//
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  routerLink,
  useParams,
  useRouteMatch,
  Redirect
} from "react-router-dom";


import {
  Paper,
  Grid,
  Button,
  CssBaseline,
  MenuItem,
  CircularProgress,
  Box,
  Divider,
  Avatar,
  InputLabel,
  Link,
  TextField
} from '@mui/material';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
  
import Header from './Header';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy, faPauseCircle } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles';

import axios from "axios";

import moment from 'moment';
//import { DayPicker } from 'react-day-picker';
import DayPicker from 'react-day-picker';
//import 'react-day-picker/lib/style.css';

import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { scaleLinear, scaleBand, scaleOrdinal, scaleLog } from '@visx/scale';
import { LegendOrdinal } from '@visx/legend';
import { Text } from '@visx/text';
//import { Axis } from "@visx/axis";
import { curveNatural, curveLinear} from "@visx/curve";
//import { LinearGradient } from "@visx/gradient";
//import { MarkerCircle } from "@visx/marker";
import { LinePath } from "@visx/shape";
import { GlyphDot, GlyphCircle } from '@visx/glyph';
import { Axis, Grid as XYGrid, LineSeries, GlyphSeries, XYChart, lightTheme, Tooltip, buildChartTheme } from '@visx/xychart';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


//

const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
}));

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

function AlertToasts({data,fit}) {

      const classes = useStyles();

      const [open, setOpen] = React.useState(false);  
      const [alertText, setAlertText] = React.useState("");

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpen(false);

      };  

      // add a trigger for this or place setOpen into error 

      useEffect(() => {

      const setMessage = () => {

        if (data == false ) {
          setAlertText("There are no season bests or races available in the selected time period. Please upload more runs and races to see your performance data."); 
          setOpen(true);
        }

        if (data == true && fit == false ) {
          setAlertText("There are not sufficient season bests or races in the selected time period to compute your performance. Therefore only your season bests and races are shown."); 
          setOpen(true);   
        }

      }

      setMessage();

      }, [ data, fit ]);

    return(
    <div>
    <Snackbar open={open} anchorOrigin={{ vertical: 'bottom' , horizontal: 'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        {alertText}
      </Alert>
     </Snackbar>  
     </div>
    )

};

const DisabledTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000000" // (default alpha is 0.38)
    }
  }
})(TextField);

//

class Performance extends React.Component {
	constructor(props) {    
		super(props);    
		this.state = {   
			userData: null,
      redirect: false,
      nMonths: 0,
      startYearAll: false,
      endYearAll: false,
      startMonthAll: false,
      endMonthAll: false,
      startYear1: -1,
      startYear2: -1,
      endYear1: null,
      endYear2: null,
      startMonth1: null,
      startMonth2: null,
      endMonth1: null,
      endMonth2: null,
      startMonth: 0,
      endMonth: 0,
      TimeVelo: null,
      fitParams: null,
      modelParams: null,
      distUnit: "m",
      distUnitUser: "",
      selectedStartDate: moment("1900-01-01").toDate(),
      selectedEndDate: moment("1900-01-01").toDate(),
      mode: 0,
      raceDist: "",
      raceTimeFormated: "",
      racePace: "",
      raceTime: 0,
      distFixed: "",
      distCustom: "",
      distMin: null,
      distMax: null
		};
    this.handleYearMonthChange1 = this.handleYearMonthChange1.bind(this);
    this.handleYearMonthChange2 = this.handleYearMonthChange2.bind(this);
    this.handleMode = this.handleMode.bind(this);
    this.handleRace = this.handleRace.bind(this);
	}

componentDidMount() {
  this.getUser();
  this.getBests(0,0,0);
}  


getUser() {
    const { match: { params } } = this.props;
    axios 
        .get(`/api/v1/athletes/${params.id}`)
        .then(response => response.data) 
        .then(data => {
          this.setState({ userData: data.userData });
        })
        .catch(error => {
            this.setState({redirect: true});
            console.log(error);
        });
}


getBests(startMonth,endMonth,mode) {
  const { match: { params } } = this.props;
  var distMin = null;
  var distMax = null;
  if (startMonth == 0 && endMonth == 0 ) { //initial call of API
   axios
    .get(`/api/v1/performance/${params.id}/?start_month=${startMonth}&end_month=${endMonth}&mode=${mode}`)
    .then(response => response.data)
    .then(data => {
      this.setState({ nMonths: data.nMonths,
                      startMonth: data.startMonth, 
                      endMonth: data.endMonth, 
                      distUnit: data.distUnit,
                      distUnitUser: data.distUnit,
                      TimeVelo: data.TimeVelo,
                      fitParams: data.fitParams,
                      modelParams: data.modelParams,
                      startYearAll: Math.ceil(data.startMonth/12)-1,
                      endYearAll:  Math.ceil(data.endMonth/12)-1,
                      startMonthAll: data.startMonth-12*(Math.ceil(data.startMonth/12)-1)-1,
                      endMonthAll: data.endMonth-12*(Math.ceil(data.endMonth/12)-1)-1,
                      startYear1: Math.ceil(data.startMonth/12)-1,
                      startYear2: Math.ceil(data.startMonth/12)-1,
                      endYear1: Math.ceil(data.endMonth/12)-1,
                      endYear2: Math.ceil(data.endMonth/12)-1,
                      startMonth1: data.startMonth-12*(Math.ceil(data.startMonth/12)-1)-1,
                      endMonth1: 11,
                      startMonth2: 0,
                      endMonth2: data.endMonth-12*(Math.ceil(data.endMonth/12)-1)-1,
                      selectedStartDate: moment().year(Math.ceil(data.startMonth/12)-1).month(data.startMonth-12*(Math.ceil(data.startMonth/12)-1)-1).endOf('month').toDate(),
                      selectedEndDate: moment().year(Math.ceil(data.endMonth/12)-1).month(data.endMonth-12*(Math.ceil(data.endMonth/12)-1)-1).endOf('month').toDate() 
                    });
      if ( data.modelParams && !data.modelParams.Es && data.modelParams.El ) {
        distMin = data.modelParams.vm * data.modelParams.tc;
        distMax = 50000;
      }

      if ( data.modelParams && data.modelParams.Es && !data.modelParams.El ) {
        if ( data.modelParams.vm * data.modelParams.tc > 400 ) {
          distMax = data.modelParams.vm * data.modelParams.tc
          distMin = 400;
        } else {
          distMax = null;
          distMin = null;
        }
      }

      if ( data.modelParams && data.modelParams.Es && data.modelParams.El ) {
        distMin = 400;
        distMax = 50000;
      }
      this.setState({ distMin: distMin, distMax: distMax})
     })
    .catch(error => {
            this.setState({redirect: true});
            console.log(error);
     });
  } else {
    var { raceDist } =  this.state;
    this.setState((state) => { return { TimeVelo: null, fitParams: null, modelParams: null }});
    axios
    .get(`/api/v1/performance/${params.id}/?start_month=${startMonth}&end_month=${endMonth}&mode=${mode}`)
    .then(response => response.data)
    .then(data => {
      this.setState({ nMonths: data.nMonths,
                      //startMonth: data.startMonth, 
                      //endMonth: data.endMonth,
                      TimeVelo: data.TimeVelo,
                      fitParams: data.fitParams,
                      modelParams: data.modelParams, 
                      //selectedStartDate: moment().year(Math.ceil(data.startMonth/12)-1).month(data.startMonth-12*(Math.ceil(data.startMonth/12)-1)-1).endOf('month').toDate(),
                      //selectedEndDate: moment().year(Math.ceil(data.endMonth/12)-1).month(data.endMonth-12*(Math.ceil(data.endMonth/12)-1)-1).endOf('month').toDate() 
                    });
      if ( data.modelParams && !data.modelParams.Es && data.modelParams.El ) {
        distMin = data.modelParams.vm * data.modelParams.tc;
        distMax = 50000;
      }

      if ( data.modelParams && data.modelParams.Es && !data.modelParams.El ) {
        if ( data.modelParams.vm * data.modelParams.tc > 400 ) {
          distMax = data.modelParams.vm * data.modelParams.tc
          distMin = 400;
        } else {
          distMax = null;
          distMin = null;
        }
      }

      if ( data.modelParams && data.modelParams.Es && data.modelParams.El ) {
        distMin = 400;
        distMax = 50000;
      }
      this.setState({ distMin: distMin, distMax: distMax});
      this.getRaceTime(this.state.raceDist,data.modelParams,distMin,distMax);
     })
    .catch(error => {
            this.setState({redirect: true});
            console.log(error);
     });
   }
}

getRaceTime(raceDist,modelParams,distMin,distMax) {
  var unit = this.state.distUnitUser;
  const vm = modelParams ? modelParams.vm : 0;
  const tc = modelParams ? modelParams.tc : 0;
  const Es = modelParams ? (modelParams.Es ? modelParams.Es : 0) : 0;
  const El = modelParams ? (modelParams.El ? modelParams.El : 0) : 0;

  if ( raceDist < distMin || raceDist > distMax || vm < 0 || tc < 0 || ( Es < 0 && El < 0) ) {
    this.setState({ raceTime: 0, raceTimeFormated: "", racePace: "" });    
  }

  if ( raceDist >= distMin && raceDist <= distMax && vm > 0 && tc > 0 && ( Es > 0 || El > 0) ) {
    axios
      .get(`/api/v1/predictor/?dist=${raceDist}&vm=${vm}&tc=${tc}&Es=${Es}&El=${El}&unit=${unit}`)
      .then(response => response.data)
      .then(data => {
        this.setState({ raceTime: data.time, raceTimeFormated: data.timeFormated, racePace: data.pace });
      })
      .catch(error => {
        this.setState({redirect: true});
        console.log(error);
      });
  } 

}

render() {

    const { match: { params } } = this.props;

    var { redirect, selectedStartDate, selectedEndDate, userData, 
      nMonths, startYear1, startYear2, endYear1, endYear2,
      startMonth1, startMonth2, endMonth1, endMonth2, TimeVelo, distUnit, distUnitUser, 
      fitParams, modelParams, mode, distFixed, distCustom, raceDist, raceTime,
      raceTimeFormated, racePace, distMin, distMax } = this.state;
      
    if (redirect) {
       return <Redirect to='/errors/401'/>;
    }

    var athleteName = "";
    var imageUrl = "";
    if (userData !== null) {
        athleteName = userData.fullname
        imageUrl = userData.image_url
    }


    const colors = [
       "#607d8b", 
       "#e91e63", 
       "#03a9f4", 
       "#9c27b0" , 
       "#e91e63"];

    const colorsOp = [
      'rgba(26,125,139,0.25)',
      'rgba(233,30,99,0.25)',
      'rgba(3,169,244,0.25)',
      'rgba(156,39,176,0.25)',
      'rgba(233,30,99,0.25)'];
    
    const customTheme = buildChartTheme({ 
      backgroundColor: '#fafafa',
      colors: ['#64b5f6','#FFB300','#e33371'], // blue, gold, red
      gridColor: '#BDBDBD',
      gridColorDark: '#BDBDBD',
    })  
    
    const height = 500;
    const width = 800;
    const margin = 50;

    const numTicks = 5;

    const xTickValuesAll = [.01,.1,.5, 1, 2, 5, 10, 20, 30, 45, 60, 90, 120, 180, 240, 300, 360,420,480];
    const yTickValuesAll = [0,50,100,150,200,250,300,350,400,450,500,550,600,650,700,750,800];

    function getTs(){
      return TimeVelo.map(d => d.t);
    }
    function getMinT(){
      return Math.min(...getTs());
    }
    function getMaxT(){
      return Math.max(...getTs());
    }

    function getVs(){
      return TimeVelo.map(d => d.v);
    }
    function getMinV(){
      return Math.min(...getVs());
    }
    function getMaxV(){
      return Math.max(...getVs());
    }

    var minT = TimeVelo ? 0.9*getMinT() : .5;
    var maxT = TimeVelo ? 1.1*getMaxT() : 360;
    var minV = TimeVelo ? 0.95*getMinV() : 0;
    var maxV = TimeVelo ? 1.05*getMaxV() : 800;

    var tStartIndex = xTickValuesAll.findIndex(t => t > minT)-1;
    var tEndIndex = xTickValuesAll.findIndex(t => t > maxT);
    var vStartIndex = yTickValuesAll.findIndex(v => v > minV)-1;
    var vEndIndex = yTickValuesAll.findIndex(v => v > maxV);

    var xTickValues = xTickValuesAll.slice(tStartIndex,tEndIndex+1);
    var yTickValues = yTickValuesAll.slice(vStartIndex,vEndIndex+1);

    var minT = xTickValues[0];
    var maxT = xTickValues[xTickValues.length-1];
    var minV = yTickValues[0];
    var maxV = yTickValues[yTickValues.length-1];

    const config = {
      x: { type: 'log' , domain: [minT,maxT], },
      y: { type: 'linear', domain: [minV,maxV], zero: false },
    };
   
    const accessors = {
      x: d =>  d ? d.t : undefined,
      y: d => d.v,
    };


    const colorAccessor = (d) => d ? (d.status == 1 ? colors[d.type] : colorsOp[d.type]) : undefined;
    const typeAccessor = (d) => d ? d.type : undefined;
    const paceAccessor = (d) => d ? d.pace : undefined;
    const nameAccessor = (d) => d ? d.name : undefined;
    const timeAccessor = (d) => d ? d.time : undefined;
    const distAccessor = (d) => d ? d.dist : undefined;
    const fracAccessor = (d) => d ? d.frac : undefined;
    const dateAccessor = (d) => d ? d.date : undefined;

    var FitData = [];
    var AALine = [];
    var AELine = [];
    if ( fitParams && modelParams ) {
      if ( modelParams.tc <= maxT && minT <= modelParams.tc ) {
        if ( modelParams.Es && modelParams.El ) {
         FitData = 
          [ {t: minT, v: 60*(fitParams[1] + fitParams[0]*Math.log(60*minT)) },
            {t: modelParams.tc, v: modelParams.vm},
            {t: maxT, v: 60*(fitParams[3] + fitParams[2]*Math.log(60*maxT))} ];
         AALine = 
          [ {t: minT, v: minV}, {t: modelParams.tc, v: minV} ];
         AELine = 
          [ {t: modelParams.tc, v: minV}, {t: maxT, v: minV} ];
        } else if ( modelParams.Es ) {
         FitData = 
          [ {t: minT, v: 60*(fitParams[1] + fitParams[0]*Math.log(60*minT)) },
            {t: modelParams.tc, v: modelParams.vm} ];
         AALine = 
          [ {t: minT, v: minV}, {t: modelParams.tc, v: minV} ];  
        } else if ( modelParams.El ) {
          FitData = 
          [ {t: modelParams.tc, v: modelParams.vm},
            {t: maxT, v: 60*(fitParams[3] + fitParams[2]*Math.log(60*maxT))} ]; 
          AELine = 
          [ {t: modelParams.tc, v: minV}, {t: maxT, v: minV} ];
        }
      }
      else if ( modelParams.tc < minT && modelParams.El ) {
         FitData = 
          [ {t: minT, v: 60*(fitParams[3] + fitParams[2]*Math.log(60*minT)) },
            {t: maxT, v: 60*(fitParams[3] + fitParams[2]*Math.log(60*maxT))} ];
         AELine = 
          [ {t: minT, v: minV}, {t: maxT, v: minV} ]; 
      }
      else if ( modelParams.tc > maxT && modelParams.Es ) {
         FitData = 
          [ {t: minT, v: 60*(fitParams[1] + fitParams[0]*Math.log(60*minT)) },
            {t: maxT, v: 60*(fitParams[1] + fitParams[0]*Math.log(60*maxT))} ]; 
         AALine = 
          [ {t: minT, v: minV}, {t: maxT, v: minV} ];
      }
    }

    const existData = TimeVelo ? (TimeVelo.length == 0 ? false : true) : true;
    const existFit = fitParams ? (fitParams.length == 0 ? false : true) : true;


    // distMin, distMax

   


    return (
    	<Fragment>
        <ThemeProvider theme={theme}>
        <div>
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap" rel="stylesheet" /> 
        </div>

        <AlertToasts data={existData} fit={existFit} />

        <Header userId = {params.id} userName = {athleteName} imageUrl = {imageUrl} />
        <div style={{marginTop: 80, marginLeft:20, marginRight:20}}>
        
    	  <Grid 
  			container
        spacing={0}
  			direction="row"
  			justify="center"
  			alignItems="flex-start"
		    >

        <Grid item xs={3} align="left">
        <Typography variant="h5" gutterBottom>
			   Performance Curve
    	  </Typography>
        <Typography variant="subtitle1" gutterBottom>
         for a selected range of months
        </Typography>
    	  </Grid>
        <Grid item xs={3} align="left">
        <Typography variant="h6" gutterBottom>
        <Box fontWeight="fontWeightBold">
          <YearMonthForm
                  startYear={startYear1}
                  endYear={endYear1}
                  startMonth={startMonth1}
                  endMonth={endMonth1}
                  date={selectedStartDate}
                  onChange={this.handleYearMonthChange1}
                  yearLabel={"Start Year"}
                  monthLabel={"Start Month"}
              />
        </Box>
        </Typography>
        </Grid>
        <Grid item xs={3} align="left">
        <Typography variant="h6" gutterBottom>
        <Box fontWeight="fontWeightBold">
          <YearMonthForm
                  startYear={startYear2}
                  endYear={endYear2}
                  startMonth={startMonth2}
                  endMonth={endMonth2}
                  date={selectedEndDate}
                  onChange={this.handleYearMonthChange2}
                  yearLabel={"End Year"}
                  monthLabel={"End Month"}
          />
        </Box>
        </Typography>
        </Grid>
        <Grid item xs={3} align="right">
        <Box fontWeight="fontWeightBold">
          <SelectMode
              mode={mode}
              onChange={this.handleMode}
          />
        </Box>
        </Grid>
		    </Grid>
    	  </div>

        <div style={{marginTop: 0, marginLeft:20, marginRight:40}}>
        <Grid 
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="flex-start"
        >  

        <Grid item xs={10} align="center">  
        {(TimeVelo && FitData) ? <div>   
        <XYChart
          theme={customTheme}
          xScale={config.x}
          yScale={config.y}
          height={height}
          width={width}
          margin={{top: margin/2, right: margin/2, bottom: margin, left: margin}}
        >

        <rect x={0} y={0} width={width} height={height} fill={'#fafafa'} />

        <Axis 
          key="time-axis"
          label={"Duration [min]"}
          orientation="bottom"
          tickValues={xTickValues}
          tickFormat={(t) =>`${t}`}
        />
        <Axis 
          key="velo-axis"
          label={"Velocity [m/min]"}
          orientation="left"
          tickValues={yTickValues}
        />
        <XYGrid
            rows={true}
            columns={false}
            tickValues={yTickValues}
        />
        <XYGrid
            rows={false}
            columns={true}
            tickValues={xTickValues}
        />
        <GlyphSeries
              dataKey="t-v-data"
              data={TimeVelo}
              xAccessor={accessors.x}
              yAccessor={accessors.y}
              colorAccessor={colorAccessor}
              size={8}
        />
        <LineSeries
          dataKey="fit"
          data={FitData}
          xAccessor={accessors.x}
          yAccessor={accessors.y}
          curve={curveLinear}
        />
        <LineSeries
          dataKey="aaline"
          data={AALine}
          xAccessor={accessors.x}
          yAccessor={accessors.y}
          curve={curveLinear}
        />
        <LineSeries
          dataKey="aeline"
          data={AELine}
          xAccessor={accessors.x}
          yAccessor={accessors.y}
          curve={curveLinear}
        />

        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showHorizontalCrosshair
          renderTooltip={({ tooltipData, colorScale }) => (
          tooltipData.nearestDatum.key == 't-v-data' ? 
          <div>
            <div style={{ color: colorAccessor(tooltipData.nearestDatum.datum) }}>
              {typeAccessor(tooltipData.nearestDatum.datum) == 4 ? <FontAwesomeIcon icon={faTrophy} /> : ''}{' '}
              {paceAccessor(tooltipData.nearestDatum.datum)}/{distUnitUser}{' for '}
              {timeAccessor(tooltipData.nearestDatum.datum)}
            </div>
            <div>
            {distAccessor(tooltipData.nearestDatum.datum)}{'m on '}
            {moment(dateAccessor(tooltipData.nearestDatum.datum)).format('MMMM Do YYYY')}
            </div>
            <div>
            {' ('}{fracAccessor(tooltipData.nearestDatum.datum)}{'% of total elapsed time)'}
            </div>
            <div>
            {nameAccessor(tooltipData.nearestDatum.datum)}
            </div>
          </div>  :  null
          )}
        />

        </XYChart>
        </div>      
          : 
          <div>
          <XYChart
          theme={customTheme}
          xScale={config.x}
          yScale={config.y}
          height={height}
          width={width}
          margin={{top: margin/2, right: margin/2, bottom: margin, left: margin}}
          >

          <rect x={0} y={0} width={width} height={height} fill={'#fafafa'} />

          </XYChart>
          </div> }
          
        </Grid> 



        <Grid item xs={2} align="left"> 

        <Typography variant="h6" gutterBottom>
          Estimated Performance
        </Typography>
        

          <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
                <TableRow>
                  <TableCell> pace @ VO2max </TableCell>
                  <TableCell style={{ width: 60 }} > {modelParams ? modelParams.vm_pace : '---'} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> time @ VO2max </TableCell>
                  <TableCell style={{ width: 60 }} > {modelParams ? modelParams.tc_formated : '---'} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> anaer. Endurance </TableCell>
                  <TableCell style={{ width: 60 }} > {modelParams ? (modelParams.Es ? modelParams.Es.toFixed(2) : '---'): '---' } </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> aer. Endurance </TableCell>
                  <TableCell style={{ width: 60 }} > {modelParams ? (modelParams.El ? modelParams.El.toFixed(2) : '---'): '---' } </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> "critical" pace </TableCell>
                  <TableCell style={{ width: 60 }} > {modelParams ? (modelParams.crit_pace ? modelParams.crit_pace : '---'): '---' } </TableCell>
                </TableRow>
          </TableBody>
          </Table>  
          </TableContainer>

      
        <Typography variant="caption" gutterBottom>
          {modelParams ? '[MAPE://'+ modelParams.MAPE.toFixed(2) + ']': ''}
        </Typography>
        </Grid>
        </Grid>


        <SelectRace
          distFixed={distFixed}
          distCustom={distCustom}
          raceTime={raceTime}
          distUnit={distUnit}
          raceTimeFormated={raceTimeFormated}
          racePace={racePace}
          distMin={distMin}
          distMax={distMax}
          onChange={this.handleRace}
        />

      </div>
      </ThemeProvider>  
    	</Fragment>
    );
  }

//

handleRace(name,value) {
  if (name == 'dist' ) {
    var unit = this.state.distUnit;
    var dist = value;
    this.setState((state) => { 
          return { distCustom: value, raceDist: value, distFixed: value, distUnit: 'm' }
        }); 
  }
  if ( name == 'distCustom') {
    var unit = this.state.distUnit;
    var dist = value;
    if ( unit == 'km') {  dist = value*1000; }
    if ( unit == 'mi') {  dist = value*1609.344; } 
    this.setState((state) => { 
          return { raceDist: dist, distCustom: value, distFixed: '' }
        }); 
  }
  if (name == 'unit') {
    this.setState((state) => { 
          return { distUnit: value }
        });
    var distInput = this.state.distCustom;
    var dist = distInput;
    if ( value == 'km') { dist = distInput*1000; }
    if ( value == 'mi') { dist = distInput*1609.344; } 
    this.setState((state) => { 
          return { raceDist: dist }
        }); 
  }
  //console.log("*********** raceDist[m]: " +  dist);
  var modelParams  = this.state.modelParams;
  this.getRaceTime(dist,modelParams,this.state.distMin,this.state.distMax); 
}

handleMode(mode) {
  const { startMonth, endMonth } = this.state;
  this.setState((state) => { 
          return { mode: mode }
        });
  this.getBests(startMonth,endMonth,mode); 
  //var modelParams  = this.state.modelParams;
  //this.getRaceTime(this.state.raceDist,modelParams);
}

handleYearMonthChange1(month) {
      //console.log("*********** handler 1: " + month );
      var endDateMax = 11;
      var startDateMin = 0;
      const { startYearAll, endYearAll, startMonthAll, endMonthAll, selectedEndDate, endMonth, mode, raceDist } = this.state;
      if ( month.getFullYear() == selectedEndDate.getFullYear() ) {
         endDateMax = selectedEndDate.getMonth();
         if ( month > selectedEndDate ) {
            month = selectedEndDate;
         }
         startDateMin = month.getMonth();
      }
      if ( selectedEndDate.getFullYear() == endYearAll && startDateMin > endMonthAll ) {
        startDateMin = endMonthAll;
      }
      if ( month.getFullYear() == startYearAll && month.getFullYear() !== endYearAll ) { 
        this.setState((state) => { 
          return { startMonth1: startMonthAll, 
                   endMonth1: Math.min(11,endDateMax),
                   startMonth2: startDateMin
          }
        }); 
      }
      else if ( month.getFullYear() !== startYearAll && month.getFullYear() == endYearAll ) {
        this.setState((state) => { 
          return { startMonth1: 0, 
                   endMonth1: Math.min(endMonthAll,endDateMax),
                   startMonth2: startDateMin,
          }
        }); 
      }
      else if ( month.getFullYear() == startYearAll && month.getFullYear() == endYearAll ) { 
        this.setState((state) => { 
          return { startMonth1: startMonthAll, 
                   endMonth1: Math.min(endMonthAll,endDateMax),
                   startMonth2: startDateMin
          }
        }); 
      } 
      else {
        this.setState((state) => { 
          return { startMonth1: 0, 
                   endMonth1: Math.min(11,endDateMax),
                   startMonth2: startDateMin,
          }
          });
      }
      this.setState((state) => { 
        return { selectedStartDate: month,
                startYear2: month.getFullYear(),
                startMonth: 12*month.getFullYear()+month.getMonth()+1
        }
      });
      if ( month < moment().year(startYearAll).month(startMonthAll).endOf('month').toDate()  ) {
        month = moment().year(startYearAll).month(startMonthAll).endOf('month').toDate();
      }
      const startMonth = 12*month.getFullYear()+month.getMonth()+1;
      //console.log("*********** handler 1 out: " + month + " => " + startMonth);
      this.getBests(startMonth,endMonth,mode); // xxx
      //var modelParams  = this.state.modelParams;
      //this.getRaceTime(this.state.raceDist,modelParams);
    }

handleYearMonthChange2(month) {
      //console.log("*********** handler 2 in: " + month );
      var startDateMin = 0;
      var endDateMax = 11;
      const { startYearAll, endYearAll, startMonthAll, endMonthAll, selectedStartDate, startMonth, mode, raceDist } = this.state;
      if ( month.getFullYear() == selectedStartDate.getFullYear() ) {
         startDateMin = selectedStartDate.getMonth(); 
         if ( month < selectedStartDate ) {
            month = selectedStartDate;
         }
         endDateMax = month.getMonth();
      }
      if ( selectedStartDate.getFullYear() == startYearAll && endDateMax < startMonthAll ) {
        endDateMax = startMonthAll;
      }
      if ( month.getFullYear() == startYearAll && month.getFullYear() !== endYearAll ) { 
        this.setState((state) => { 
          return { startMonth2: Math.max(startMonthAll,startDateMin), 
                   endMonth2: 11,
                   endMonth1: endDateMax
          }
        }); 
      }
      else if ( month.getFullYear() !== startYearAll && month.getFullYear() == endYearAll ) {
        this.setState((state) => { 
          return { startMonth2: Math.max(0,startDateMin), 
                   endMonth2: endMonthAll,
                   endMonth1: endDateMax
          }
        }); 
      }
      else if ( month.getFullYear() == startYearAll && month.getFullYear() == endYearAll ) { 
        this.setState((state) => { 
          return { startMonth2: Math.max(startMonthAll,startDateMin), 
                   endMonth2: endMonthAll,
                   endMonth1: endDateMax
          }
        }); 
      } 
      else {
        this.setState((state) => { 
          return { startMonth2: Math.max(0,startDateMin), 
                   endMonth2: 11,
                   endMonth1: endDateMax
          }
        });
      }
      this.setState((state) => { 
        return { selectedEndDate: month,
                 endYear1: month.getFullYear(),
                 endMonth: 12*month.getFullYear()+month.getMonth()+1
        }
      });
      if ( month > moment().year(endYearAll).month(endMonthAll).endOf('month').toDate()  ) {
        month = moment().year(endYearAll).month(endMonthAll).endOf('month').toDate();
      }
      const endMonth = 12*month.getFullYear()+month.getMonth()+1;
      //console.log("*********** handler 2 out: " + month + " => " + endMonth);
      this.getBests(startMonth,endMonth,mode); // xxx
      //var modelParams  = this.state.modelParams;
      //this.getRaceTime(this.state.raceDist,modelParams);
    }    
}

function SelectMode({ mode, onChange }) {
  const classes = YearMonthFormStyles();

  const handleChange = function handleChange(e) {
    mode = e.target.value; 
    onChange(mode);
  };

  return(
    <Fragment>
    <ThemeProvider theme={theme}>
    <div>
    <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-bests">Select Bests</InputLabel>
              <Select
                label="Select Bests"
                labelId="select-bests-label"
                id ="select-bests"
                value={mode}
                onChange={handleChange}
              >
        
                <MenuItem value={0}>
                All best efforts and races
                </MenuItem>
                <MenuItem value={1}>
                Full time best efforts and races
                </MenuItem>
                <MenuItem value={2}>
                Only races
                </MenuItem>
          
              </Select>
    </FormControl>
    </div>
    </ThemeProvider>
    </Fragment>
    );

}

//

function SelectRace({ distFixed, distCustom, distUnit, raceTime, 
    raceTimeFormated, racePace, distMin, distMax, onChange }) {
  const classes = YearMonthFormStyles();
  const distMinFormated = distMin ? distMin.toFixed(0) + 'm' : '';
  const distMaxFormated = distMax ? (distMax/1000).toFixed(0) + 'km' : '';

  const handleChange = function handleChange(e) {
    //console.log(e.target.name + ": " + e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    onChange(name,value);
  };

  return(
    <Fragment>
    <ThemeProvider theme={theme}>
    <div style={{marginTop: 0, marginLeft:20, marginRight:20}}>
    <Grid 
        container
        spacing={0}
        direction="row"
        justify="left"
        alignItems="flex-start"
    >
    <Grid item xs={3} align="left">
    <Typography variant="h6" gutterBottom>
      Predict Race:
    </Typography>
    <Typography variant="subtitle2" gutterBottom>
      {(distMin && distMax) ? '(distance between ' + distMinFormated + ' and ' + distMaxFormated + ')' : ''}
    </Typography>
    </Grid>
    <Grid item xs={3} align="center">
    
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="left"
    >
    <Box >
    <FormControl variant="outlined" style={{minWidth: 160}} className={classes.formControl} >
      <InputLabel id="select-dist">Select distance</InputLabel>
        <Select
          name="dist"
          label="Select distance"
          labelId="select-dist-label"
          id ="select-dist"
          value={distFixed}
          onChange={handleChange}
        > 
          <MenuItem value={400}>
            400m
          </MenuItem>
          <MenuItem value={800}>
            800m
          </MenuItem>
          <MenuItem value={1000}>
            1000m
          </MenuItem>
          <MenuItem value={1500}>
            1500m
          </MenuItem>
          <MenuItem value={1609.34}>
            1mi
          </MenuItem>
          <MenuItem value={2000}>
            2000m
          </MenuItem>
          <MenuItem value={3000}>
            3000m
          </MenuItem>
          <MenuItem value={3218.68}>
            2mi
          </MenuItem>
          <MenuItem value={5000}>
            5km
          </MenuItem>
          <MenuItem value={10000}>
            10km
          </MenuItem>
          <MenuItem value={15000}>
            15km
          </MenuItem>
          <MenuItem value={16093.40}>
            10mi
          </MenuItem>
          <MenuItem value={20000}>
            20km
          </MenuItem>
          <MenuItem value={21097.5}>
            Halfmarathon
          </MenuItem>
          <MenuItem value={30000}>
            30km
          </MenuItem>
          <MenuItem value={42195}>
            Marathon
          </MenuItem>
          <MenuItem value={50000}>
            50km
          </MenuItem>
        </Select>
    </FormControl>
    </Box>
    <Box mx={3}>
    <Typography variant="body1"  >
      or 
    </Typography>
    </Box>
    </Box>

    </Grid>
    <Grid item xs={3} align="left">
    <Stack  direction="row" spacing={2}>
    <FormControl style={{maxWidth: 130}} className={classes.formControl}>
        <TextField
          name="distCustom"
          error={false}
          id="custom-distance"
          label="Custom dist."
          value={distCustom}
          variant="outlined"
          onChange={handleChange}
        />
    </FormControl>
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 60 }}  className={classes.formControl}>
    <InputLabel id="select-unit">Unit</InputLabel>
      <Select
          name="unit"
          label="Unit"
          labelId="select-unit-label"
          id ="select-unit"
          value={distUnit}
          onChange={handleChange}
        > 
          <MenuItem value='m'>
            m
          </MenuItem>
          <MenuItem value='km'>
            km
          </MenuItem>
          <MenuItem value='mi'>
            mi
          </MenuItem>              
        </Select>
    </FormControl>
    </Stack>
    </Grid>
    <Grid item xs={3} align="left">
    <Stack  sx={{ width: 400 }} direction="row" spacing={2}>
    <FormControl style={{ maxWidth: 120 }} className={classes.formControl}>
        <DisabledTextField
          error={false}
          id="race-time"
          label="Race Time"
          value={raceTimeFormated}
          variant="outlined"
        />
    </FormControl>
    <FormControl style={{maxWidth: 120}} className={classes.formControl}>
        <DisabledTextField
          error={false}
          id="race-pace"
          label="Race Pace"
          value={racePace}
          variant="outlined"
        />
    </FormControl>
    </Stack>
    </Grid>
    </Grid>
    </div>
    </ThemeProvider>
    </Fragment>
    );

}


function YearMonthForm({ startYear, endYear, startMonth, endMonth, date, onChange, yearLabel, monthLabel }) {
  const classes = YearMonthFormStyles();
  const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 
    'August', 'September', 'October', 'November', 'December'].slice(startMonth,endMonth+1);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const fromMonth = new Date(startYear, startMonth);
  const toMonth = new Date(endYear, endMonth);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    //console.log(e.target.name + ": " + e.target.value);
    var year  = date.getFullYear();
    var month = date.getMonth();
    if (e.target.name.includes('Month')) { month = e.target.value; }
    if (e.target.name.includes('Year')) { year = e.target.value; }
    //console.log("year: " + year + "month: " + month);
    onChange(moment().year(year).month(month).endOf('month').toDate());
  };

  return (
    <Fragment>
    <ThemeProvider theme={theme}>
    <div>
    <Stack  direction="row" spacing={2}>
    <FormControl variant="outlined" sx={{ minWidth: 80 }} className={classes.formControl}>
      <InputLabel id="select-year-label">{yearLabel}</InputLabel>
      { startYear != -1 ?
      <Select
        name={yearLabel}
        labelId="select-year-label"
        id = {yearLabel}
        value={date.getFullYear()}
        onChange={handleChange}
        label={yearLabel}
      >
        {years.map(year => (
              <MenuItem key={year} value={year}>
              {year}
              </MenuItem>
          ))}
      </Select> : 
      <Select
        name={yearLabel}
        labelId="select-year-label"
        id = {yearLabel}
        value={""}
        onChange={handleChange}
        label={yearLabel}
        displayEmpty
      > 
      []
      </Select> }
    </FormControl>
    <FormControl variant="outlined" sx={{ minWidth: 120 }} className={classes.formControl}>
      <InputLabel id="select-month-label">{monthLabel}</InputLabel>
      { startYear != -1 ?
      <Select
        name={monthLabel}
        labelId="select-month-label"
        id = {monthLabel}
        value={date.getMonth()}
        onChange={handleChange}
        label={monthLabel}
      >
        {months.map((month,i) => (
              <MenuItem key={month} value={i+startMonth}>
              {month}
              </MenuItem>
          ))}
      </Select> :
      <Select
        name={monthLabel}
        labelId="select-month-label"
        id = {monthLabel}
        value={""}
        onChange={handleChange}
        label={monthLabel}
        displayEmpty
      > 
      []
      </Select> }
    </FormControl>
    </Stack>
    </div>
    </ThemeProvider>
    </Fragment>
  );
}

const YearMonthFormStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



export default Performance;






