import React from "react";
import $ from 'jquery'; 
import { Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";

class Logout2 extends React.Component {
	state = {
		navigate: false
	}


handleLogout = () => {
    $.ajaxSetup({
 		headers:
 		{ 'X-CSRF-TOKEN': $('meta[name=”csrf-token”]').attr('content') }
		});
    $.ajax({
      type: "Delete",
      url: "/signout",
      dataType: "json",
      error: function (error) {
        console.log(error);
      },
      success: function (res) {
        console.log(res);
        this.setState({ navigate: true });
      }.bind(this)
    });
  };

render() {
	const { navigate } = this.state;

	if (navigate) {
		return <Redirect to="/" push={true} />;
	}	

	return <div onClick={this.handleLogout}>Log out</div>;	
}

}



export default Logout2;


