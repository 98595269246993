import React from 'react';
import config from 'react-global-configuration';
import { FormattedMessage } from 'react-intl';

import { List, ListItem, withStyles } from '@material-ui/core';
import Link from '@mui/material/Link';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import Favorite from '@material-ui/icons/Favorite';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

function Footer({ ...props }) {


  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "white",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Container maxWidth="lg">
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <Typography  variant="body1">
              &copy; {1900 + new Date().getYear()}{' '}by{' '}
              <Link href={config.get('app.founder.website')} sx={{ color: 'primary.main' }} variant="body1" target="_blank" rel="noopener noreferrer">
                Thorsten Emig
              </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>    
      </Box>  
    </ThemeProvider>
  );
}

export default Footer;
