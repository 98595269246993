import React from "react";
import Routes from "../routes/Index";
import FlashState from "./FlashState"



// export default props => <React.Fragment>{Routes}</React.Fragment>;

class App extends React.Component {
	
	render() {
	   		
	return(
	<React.Fragment>
	{Routes}
	</React.Fragment>
	);

	};

}

export default App;

