import {
  makeStyles,
  IconButton,
  Drawer,
} from "@material-ui/core";

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import React, { useState, useEffect } from "react";
import { Link as RouterLink, BrowserRouter as Router } from "react-router-dom";
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'

//import Logout from './Logout';
import Logout2 from './Logout2';



var imgLogo =require('./../../../public/sciendure-logo-s.png')

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#000',
    },
    secondary: {
      light: '#E0E0E0',
      main: '#000000',
      dark: '#E0E0E0',
      contrastText: '#000000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            color: '#000000',
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            color: '#000000',
          },
        }),
      },
    },    
  },
});

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFFFF",
    paddingRight: "40px",
    paddingLeft: "50px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#ad123c",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "0px 0px",
  },
}));

export default function Header( { userId, userName, imageUrl } ) {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();

  const headersData = [
  {
    label: "Timeline",
    href: `/timeline/${userId}`,
  },
  {
    label: "Performance",
    href: `/performance/${userId}`,
  },
  ];

  const settingsUrl = `/settings/${userId}`;

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

  
  setResponsiveness();

  window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const guestMenu = () => {
    return(
      <Button 
        aria-controls="menu-appbar"
        //color="default"
      > 
      <Avatar src={imageUrl} />
      </Button>
      );
  };

  const userMenu = (setAnchorEl, anchorEl, handleMenu, handleClose) => {
    return(
      <ThemeProvider theme={theme}>
        <div>
              <Button color="secondary"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                //color="default"
                endIcon={<FontAwesomeIcon icon={faChevronDown} />}
              > 
              <Avatar src={imageUrl} />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getcontentanchorel={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Link
                  {...{
                  component: RouterLink,
                  to: settingsUrl,
                  color: "inherit",
                  style: { textDecoration: "none" },
                  key: "settings",
                  onClick: handleClose,
                  }}
                  >
                  <MenuItem>Settings</MenuItem>
                </Link>
                <MenuItem><Logout2 /></MenuItem>
              </Menu>
    </div>
    </ThemeProvider>
    );
  };





  const displayDesktop = ( setAnchorEl, anchorEl ) => {
    

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <ThemeProvider theme={theme}>
      <Toolbar>
        <a href={'/'} title="Sciendure" >
        <Avatar sx={{ width: 55, height: 55 }} alt="Sciendure" variant="square" src={imgLogo} className={toolbar} />
        </a>
        {headerLogo}
        
        
        <div style={{ flex: 1 }}>
          {getMenuButtons()}
        </div>
        

        <div>
              <Typography variant="body1" style={{color: "#000000"}}>
              {userName}
              </Typography>
        </div>
       
        {localStorage.getItem("loggedIn") ? userMenu( setAnchorEl, anchorEl, handleMenu, handleClose ):guestMenu()}

      </Toolbar>
      </ThemeProvider>
    );
  };

  const displayMobile = ( setAnchorEl, anchorEl ) => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
      
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            //color: "default",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <a href={'/'} title="Sciendure" >
        <Avatar sx={{ width: 55, height: 55 }} alt="Sciendure" variant="square" src={imgLogo} className={toolbar} />
        </a>
        <div style={{ flex: 1 }}>
        {headerLogo}
        </div>
        
        <div>
              <Typography variant="body1" style={{color: "#000000"}}>
              {userName}
              </Typography>
        </div>
      
        {localStorage.getItem("loggedIn") ? userMenu( setAnchorEl, anchorEl, handleMenu, handleClose ):guestMenu()}

      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        //<a style={{textDecoration: 'none'}} href={href} key={label}> 
        //<Typography variant="h6" component="h1"  > {label} </Typography>
        //</a>
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const headerLogo = (  
    <Typography variant="h6" component="h1" className={logo} style={{marginLeft:15 , fontWeight: 700}}>
      Sciendure
    </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        //<a style={{textDecoration: 'none'}} href={href} key={label}> 
        //<Button  className={menuButton}  style={{marginLeft:15}}> {label} </Button>
        //</a>
        <Button  color="secondary" style={{ marginLeft: 38 , fontWeight: 700}}
          {...{
            key: label,
            to: href,
            component: RouterLink,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
  <ThemeProvider theme={theme}>
    <header>
      <AppBar className={header} >
        {mobileView ? displayMobile( setAnchorEl, anchorEl ) : displayDesktop( setAnchorEl, anchorEl )}
      </AppBar>
    </header>
  </ThemeProvider>
  );
}
