const configuration = {
  app: {
    name: 'sciendure',
    demoAthleteId: '0',
    gitHubUrl: 'https://github.com/',
    licenseUrl: 'https://github.com/',
    founder: {
      stravaId: '',
      website: 'https://emig-group.org',
    },
    redirectUriPath: '/auth/exchange-token',
    stravaApiClientId: process.env.STRAVA_API_CLIENT_ID,
  },
};

export default configuration;
