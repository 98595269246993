import React from 'react';
import { FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@mui/material/Typography';

import GridContainer from './../material-kit-react/Grid/GridContainer.jsx';
import GridItem from './../material-kit-react/Grid/GridItem.jsx';
import { title } from './../material-kit-react/Styles/material-kit-react.jsx';

const featuresStyle = {
  section: {
    padding: '20px 0',
  },
  title: {
    ...title,
    marginBottom: '30px',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
    textAlign: 'center',
  },
  description: {
    color: '#3c4858',
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  textArea: {
    marginRight: '15px',
    marginLeft: '15px',
  },
};

class SectionFeatures extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.section} content-section`}>
        <GridContainer justifyContent="center">
          <GridItem cs={12} sm={12} md={8}>
            <div id="features" className={classes.title + ' placeholder-header'} />
            <Typography variant="h5" gutterBottom className={classes.description}>
              <FormattedMessage id="home.sectionFeatures.title" />
            </Typography>
            <Typography variant="h6" gutterBottom className={classes.description}>
              <FormattedMessage id="home.sectionFeatures.paragraph1" />
            </Typography>
            <Typography variant="h6" gutterBottom className={classes.description}>
              <FormattedMessage id="home.sectionFeatures.paragraph2" />
            </Typography>
            <Typography variant="h6" gutterBottom className={classes.description}>
              <FormattedMessage id="home.sectionFeatures.paragraph3" />
            </Typography>
            <Typography variant="h6" gutterBottom className={classes.description}>
              <FormattedMessage id="home.sectionFeatures.paragraph4" />
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(featuresStyle)(SectionFeatures);
