import * as React from 'react';
import { Component, Fragment, forwardRef, useState, useEffect, useCallback } from 'react';
import $ from 'jquery'; 
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import imgBackground from './../assets/img/main-bg.jpg';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import FlashState from './FlashState';

var imgLogo =require('./../../../public/sciendure-logo-s.png')


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://sciendure.com/">
        sciendure.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//

function AlertToasts( props ) {

      const [open, setOpen] = React.useState(false);  
      const [alertText, setAlertText] = React.useState("");

      useEffect(() => {

      const setMessage = ( msg ) => {

        if (props.show == true ) {
          setAlertText( msg ); 
          setOpen(true);
        }

      }

      setMessage( props.errorMessage );

      }, [ props.show ]);

    return(
    <div>
      <Snackbar open={open} anchorOrigin={{ vertical: 'top' , horizontal: 'right'}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          {alertText}
        </Alert>
      </Snackbar>  
    </div>
    )

};

//

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

//

class RequestPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUnsuccessful: false,
      errorMessage: null,
      navigate: false,
      userId: null
    };

  }


  handleLogin = () => {
  var that = this;
  var userInfo = {
   user: {
    email: document.getElementById('email').value,
   },
   turbo: false 
  };
  $.ajaxSetup({
    headers:
    { 'X-CSRF-TOKEN': $('meta[name=”csrf-token”]').attr('content') }
  });
  $.ajax({
   type: "POST",
   url: "/users/password",
   dataType: 'json',
   data: userInfo,
   error: function (xhr) {
    var error = $.parseJSON(xhr.responseText).error
    //if ( error == 'user not confirmed' ) 
    //  { that.setState({ errorMessage: 'Please confirm your email first. Then try again.' }) }
    //else 
    //  { that.setState({ errorMessage: 'The login credentials were incorrect. Please try again.' }) }
    console.log('error code: ' + xhr.status)
    console.log('error msg: ' + error)
   },
   success: function (res) {
      that.props.changePage("home");
      FlashState.set('message', 'If an account exists for ' + document.getElementById('email').value + " we'll send you an email with instructions how to reset your password.");
    }
  });
  };
 

render() {

  return (
  <Fragment>
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${imgBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        <Stack sx={{ m: 6 }} spacing={2} direction="row">
        <a onClick={() => this.props.changePage("home")}  style={{cursor:'pointer'}}>
        <Avatar variant="square" src={imgLogo} sx={{ width: 70, height: 70 }}/>
        </a>
        <Typography variant="h2" component="h1" sx={{ color: 'primary.main' }} >
        Sciendure
        </Typography>
        </Stack>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <a onClick={() => this.props.changePage("home")}  style={{cursor:'pointer'}} >
            <Avatar sx={{ m: 1 }} variant="square" src={imgLogo} sx={{ width: 50, height: 50 }}/>
            </a>
            <Typography component="h1" variant="h5">
              Reset Your Password
            </Typography>
            <Typography component="h6" align="center">
              Enter your email and we'll send you a link with instructions
            </Typography>
            <AlertToasts show={this.state.loginUnsuccessful} errorMessage={this.state.errorMessage} />
            <Box component="form" noValidate  sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              
              <Button
                onClick={this.handleLogin}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
              
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    </Fragment>
  )};
}

export default withRouter(RequestPassword);