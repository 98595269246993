import React from "react";
import {  Router, Route, Switch } from "react-router-dom";
import Landing from "../components/Landing";
import CalendarApp from "../components/CalendarApp";
import Settings from "../components/Settings";
import Performance from "../components/Performance";
import ErrorPage from "../components/ErrorPage";
import { IntlProvider } from '../components/Components/IntlContext';
import { createBrowserHistory } from 'history';
//import '../assets/scss/material-kit-react.scss';
//import '../assets/scss/custom-styles.scss';

var hist = createBrowserHistory();

const errorPage400 = () => <ErrorPage errorCode="400" />;
const errorPage401 = () => <ErrorPage errorCode="401" />;
const errorPage403 = () => <ErrorPage errorCode="403" />;
const errorPage404 = () => <ErrorPage errorCode="404" />;
const errorPage500 = () => <ErrorPage errorCode="500" />;
const errorPage503 = () => <ErrorPage errorCode="503" />;


export default (
<IntlProvider>
  <Router history={hist} >
    <Switch>
      <Route path="/" exact> <Landing page={"home"}/> </Route>
      <Route path="/login" exact> <Landing page={"login"} /> </Route>
      <Route path="/newlogin" exact component={(props) =>  <Landing page={"login"} {...props} />} />
      <Route path="/users/password/edit" exact component={(props) =>  <Landing page={"resetpw"} {...props} />} />
      <Route path="/timeline/:id" exact component={CalendarApp} />
      <Route path="/settings/:id" exact component={Settings} />
      <Route path="/performance/:id" exact component={Performance} />
      <Route path="/errors/400" key="ErrorPage400" component={errorPage400} />
      <Route path="/errors/401" key="ErrorPage401" component={errorPage401} />
      <Route path="/errors/403" key="ErrorPage403" component={errorPage403} />
      <Route path="/errors/404" key="ErrorPage404" component={errorPage404} />
      <Route path="/errors/500" key="ErrorPage500" component={errorPage500} />
      <Route path="/errors/503" key="ErrorPage503" component={errorPage503} />  
    </Switch>
  </Router>
</IntlProvider>
);
