import * as React from 'react';
import { Component, Fragment, forwardRef, useState, useEffect, useCallback } from 'react';
import { Redirect } from "react-router-dom";
import $ from 'jquery'; 
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import imgBackground from './../assets/img/main-bg.jpg';

import { Form } from 'react-final-form';
import * as Yup from 'yup';

import {
  Checkboxes,
  Radios,
  Select,
  DatePicker,
  TextField,
  makeValidate,
  showErrorOnBlur
} from 'mui-rff';

import 'date-fns';
import { parseISO, format } from 'date-fns';
import { convertToLocalTime } from 'date-fns-timezone';
import differenceInYears from "date-fns/differenceInYears";
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import FlashState from './FlashState';

var imgLogo =require('./../../../public/sciendure-logo-s.png')

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AlertToasts( props ) {

      const [open, setOpen] = React.useState(false);  
      const [alertText, setAlertText] = React.useState("");

      useEffect(() => {

      const setMessage = () => {

        if (props.show == true ) {
          setAlertText("The registration failed. A user with this email address probably has been created already."); 
          setOpen(true);
        }

      }

      setMessage();

      }, [ props.show ]);

    return(
    <div>
      <Snackbar open={open} anchorOrigin={{ vertical: 'top' , horizontal: 'right'}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          {alertText}
        </Alert>
      </Snackbar>  
    </div>
    )

};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://sciendure.com/">
        sciendure.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

const HelpTooltip = styled(({ className, ...props }, TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#212121',
    color: '#FFFFFF',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const schema = Yup
    .object()
    .shape({
      firstName: Yup.string().required('First name is required.')
        .max(80, 'First name must not exceed 80 characters'),
      lastName: Yup.string()
        .required('Last name is required.')
        .max(80, 'Last name must not exceed 80 characters'),
      email: Yup.string()
        .email('Email is invalid')
        .required('Email is required.'),
      password: Yup.string()
        .required('Password is required.')
        .min(6, 'Password must be at least 6 characters')
        .max(40, 'Password must not exceed 40 characters'),
      date: Yup.date()
        .typeError('Please enter a valid date')
        .required('Birthday is required. ')
        .test("birthday", "You must be over 18", function (value) {
                return differenceInYears(new Date(), new Date(value)) >= 18;
              }),
      gender: Yup.string().required('Gender is required. ')        
    });

const validate = makeValidate(schema);


//

function formatDate(date) {
    
      // Get the timezone from browser using native methods
      // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // const dateTmp = Date.parse(date.toLocaleString());

      // const localDate = convertToLocalTime(dateTmp, {
      //   timeZone: timezone,
      // });

      //return format(localDate, 'yyyy-MM-dd');
    
      return format(date, 'yyyy-MM-dd');
    
};

//

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signupUnsuccessful: false,
      gender: '',
    };
  }


  handleRegister = ( values ) => {
  var that = this;  
  var userInfo = {
      user: {
        email: values.email,
        password: values.password,
        password_confirmation: values.password,
        athlete_info_attributes: {
          firstname: values.firstName,
          lastname: values.lastName,
          birthday: formatDate(values.date),
          sex: values.gender
        }
      },
      turbo: false 
  };
  //console.log("date: " + userInfo.user.athlete_info_attributes.birthday);
  $.ajaxSetup({
    headers:
    { 'X-CSRF-TOKEN': $('meta[name=”csrf-token”]').attr('content') }
  });
  $.ajax({
      type: "POST",
      url: "/users",
      dataType: "json",
      data: userInfo,
      error: function (error) {
        that.setState({
          signupUnsuccessful: true
        });
      },
      success: function (res) {
        that.props.changePage("home");
        FlashState.set('message', 'Welcome ' + values.firstName + '! You have signed up successfully. A message with a confirmation link has been sent to your email '
          + values.email + '. Please follow the link to activate your account.');
        that.props.updateCurrentUser(res.email);
      }
  });
  };


  render() {  
    
  const formFields = [
    {
    size: 12,
    field: (  
      <TextField
        autoComplete="given-name"
        name="firstName"
        required={true}
        id="firstName"
        label="First Name"
      />
    )
    },
    {
    size: 12,
    field: (  
      <TextField
        required={true}
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="family-name"
      />
    )
    },
    {
    size: 12,
    field: (  
      <TextField
        margin="normal"
        required={true}
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
      />
    )
    },
    {
    size: 12,
    field: (    
      <TextField
        margin="normal"
        required={true}
        name="password"
        label="Password"
        type="password"
        id="password"
      />
    )
    },
    {
    size: 6,
    field: (
      <>
      <HelpTooltip
        title={
          <React.Fragment>
            <b>Why is my date of birth required?</b>
              {' '}
              {"Your date of birth allows us to enhance the analysis of your physiological data. "}
              {"We also use this information in an anonymous form for research. We do not share your date of birth."}
          </React.Fragment>
        }
      >
      <HelpIcon sx={{ fontSize: 20}} />
      </HelpTooltip>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        name="date"
        id="date"
        margin="none"
        label="Birthday"
        format="dd-MMM-yyyy"
        required={true}
        showError={showErrorOnBlur}
      />
      </LocalizationProvider>
      </>
    )
    },
    {
    size: 6,
    field: (
      <>
      <HelpTooltip
        title={
          <React.Fragment>
            <b>Why is my gender required?</b>
              {' '}
              {"Gender is used to analyse your performance data and in an anonymous form for research. We do not share your gender."}
          </React.Fragment>
        }
      >
      <HelpIcon sx={{ fontSize: 20}} />
      </HelpTooltip>
        <Select
          name="gender"
          label="Gender"
          id="gender"
          required={true}
        >
          <MenuItem value={'M'}>Man</MenuItem>
          <MenuItem value={'F'}>Women</MenuItem>
          <MenuItem value={'E'}>Prefer not to say</MenuItem>
          <MenuItem value={'N'}>Non-binary</MenuItem>
        </Select>
      </>
    )  
    }
  ];

  
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${imgBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        <Stack sx={{ m: 6 }} spacing={2} direction="row">
        <a onClick={() => this.props.changePage("home")}  style={{cursor:'pointer'}}>
        <Avatar variant="square" src={imgLogo} sx={{ width: 70, height: 70 }}/>
        </a>
        <Typography variant="h2" component="h1" sx={{ color: 'primary.main' }}>
        Sciendure
        </Typography>
        </Stack>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <a onClick={() => this.props.changePage("home")}  style={{cursor:'pointer'}}>
            <Avatar sx={{ m: 1 }} variant="square" src={imgLogo} sx={{ width: 50, height: 50 }}/>
            </a>
            <Typography component="h1" variant="h5">
              Join Sciendure for free
            </Typography>
            <Box style={{ marginTop: 30}} >
            <Form 
              onSubmit={this.handleRegister}
              validate={validate} >
                {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2}>
                  {formFields.map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                  <Grid container justifyContent="center" alignItems="center" >
                  <Grid item style={{ marginTop: 16 }} >
                    <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    sx={{ mt: 3, mb: 2 }}
                    >
                    Agree and Sign up
                    </Button>
                  </Grid>
                  </Grid>
                </Grid>
                </form>
              )}
            </Form>
            </Box>
              <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                <Grid item xs={12}>
                  <Link href="/terms" variant="body2">
                  By signing up for Sciendure, you agree to our Terms of Service.
                  </Link>
                </Grid>  
                <Grid item xs={12}>  
                  <Link href="/privacy" variant="body2">
                    {"View our Privacy Policy."}
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link onClick={() => this.props.changePage("login")} variant="body2" style={{cursor:'pointer'}}>
                    {"Already a Member? Log in"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
      <AlertToasts show={this.state.signupUnsuccessful} />
    </ThemeProvider>
  )};
}

export default Register


