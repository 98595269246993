//import 'core-js/es6/map';
//import 'core-js/es6/set';

import * as React from 'react';

import Home from './Home';
import Register from './Register';
import Login from './Login';
import RequestPassword from './RequestPassword'
import ResetPassword from './ResetPassword'

class Landing extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			page: props.page,
			currentUser: null,
			signupUnsuccessful: false
		};
	}

	changePage = (newPage) => {
    	this.setState({
      		page: newPage
    	});
  	};

  	updateCurrentUser = (email) => {
    	this.setState({
      		currentUser: email
    	});
  	}


  	render() {
    	switch(this.state.page) {
      		case "home":
        		return <Home changePage={this.changePage} updateCurrentUser={this.updateCurrentUser}/>;
      		case "register":
        		return <Register changePage={this.changePage} updateCurrentUser={this.updateCurrentUser}/>;
        	case "login":
        		return <Login currentUser={this.currentUser} changePage={this.changePage} updateCurrentUser={this.updateCurrentUser}/>;
        	case "requestpw":
        		return <RequestPassword changePage={this.changePage} updateCurrentUser={this.updateCurrentUser}/>;
        	case "resetpw":
        		return <ResetPassword changePage={this.changePage} updateCurrentUser={this.updateCurrentUser}/>;	
    		default: 
    			return null; 
    	}
  	};

};

export default Landing;


