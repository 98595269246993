import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
//import { render } from "react-dom";
import { createRoot } from 'react-dom/client';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';
import Popper from 'popper.js';
//import 'bootstrap/dist/js/bootstrap.bundle.min';

import '../errors/styles/main';
import 'bootstrap/dist/css/bootstrap';
import 'font-awesome/css/font-awesome';
//import 'admin-lte/plugins/pace/pace.min';
//import 'admin-lte/dist/css/AdminLTE.min';
//import 'admin-lte/dist/css/skins/skin-black-light.min';
import 'datatables.net-bs/css/dataTables.bootstrap';
import 'toastr/build/toastr';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import '../athletes';
//import '../athletes/styles/main';

//import './../home/assets/scss/material-kit-react.css?v=1.3.0';
//import "./../home/assets/scss/custom-styles";

//import "./../home";

//Pace.options.ajax.trackWebSockets = false;

import App from "../components/App";

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
});


document.addEventListener("DOMContentLoaded", () => {
  const container = document.body.appendChild(document.createElement("div"));
  //var style = window.getComputedStyle(container, null).getPropertyValue('font-size');
  //var fontSize = parseFloat(style); 
  //console.log(fontSize); // 16
  const root = createRoot(container); 

  root.render(
  	<React.Fragment>
    <ThemeProvider theme={theme}>
  		<CssBaseline />
    	<App />
    </ThemeProvider>  
    </React.Fragment>
  );
});

