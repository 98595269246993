import React, { Component, Fragment, forwardRef, useState, useEffect } from 'react'


//

import memoize from "fast-memoize";
import Helmet from 'react-helmet';
import config from 'react-global-configuration';
//
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import Link from '@mui/material/Link';

import Header from './Header';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy, faPauseCircle, faChevronDown, faChevronUp, 
  faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
//
import { CircularProgress, Box, Divider, Grid, Avatar, Snackbar} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import { Alert, AlertTitle} from '@material-ui/lab';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from "react-window-infinite-loader"
import AutoSizer from "react-virtualized-auto-sizer"
import axios from "axios";

//import * as d3 from "d3";
//import { scaleOrdinal, scaleLinear, scaleBand, rangeRound } from 'd3-scale';
//import { } from 'd3-axis';
//import { max } from 'd3-array';
//import { select } from 'd3-selection';

import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { scaleLinear, scaleBand, scaleOrdinal, scaleLog } from '@visx/scale';
import { LegendOrdinal } from '@visx/legend';
import { Text } from '@visx/text';

import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
//import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './../../assets/stylesheets/day-picker.css'

//var imgLogo =require('./../../../../public/sciendure-logo-s.png')
//function Alert(props) {
//  return <MuiAlert elevation={6} variant="filled" {...props} />;
//}

const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
  }));

const theme = createTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      light: '#d01647',
      main: '#ad123c',
      dark: '#730d28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc94d',
      main: '#ffb300',
      dark: '#e6a100',
      contrastText: '#000',
    },
  },
});

class CalendarApp extends React.Component {
	constructor(props) {    
		super(props);    
		this.state = {
      userData: null,      
			calItems: [],
      bests: [],
      bestsYear: [],
      bestsAll: [],
      startMonth: null,
      startYear: null,
      startYearAll: null,
      distUnit: "",
			itemNo: 0,
      selectedDay: moment(new Date()).startOf('day').toDate(), // selectedDay must be always earlier than or equal to initDate
      //month: moment().subtract(1, "months").toDate(),
      month: moment(moment(new Date()).startOf('day').toDate(),"YYYY-M-D").subtract(1, 'months').toDate(),
      initDate: moment(new Date()).startOf('day').toDate(),
			requestCache: {},
			// the order of the modifiers determines their dominance in the calendar box
      modifiers: {
  			run: [],
  			longrun: [],
  			workout: [],
  			race: [],
        runSelected: [],
        longrunSelected: [],
        workoutSelected: [],
        raceSelected: [],
      },
      redirect: false,
      ActivityEditId: null,
      ActivityEditexternalID: null,
      ActivityEditOpen: false,
      ActivityEditName: "",
      ActivityEditDate: null,
      ActivityEditWotid: "0",
      ActivityEditBests: true,
      ActivityEditItemIndex: null,
      ActivityEditIndex: null,
		};
		//this.getCalendarItems = this.getCalendarItems.bind(this);
		this.handleDayClick = this.handleDayClick.bind(this);
    this.fct = this.fct.bind(this);
		this.loadMoreItems = this.loadMoreItems.bind(this);
		this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.handleCloseActivityEdit = this.handleCloseActivityEdit.bind(this);
    this.handleRaceForward = this.handleRaceForward.bind(this);
    this.handleRaceBack= this.handleRaceBack.bind(this);
    this.handleWorkoutForward = this.handleWorkoutForward.bind(this);
    this.handleWorkoutBack= this.handleWorkoutBack.bind(this);
    this.handleLongrunForward = this.handleLongrunForward.bind(this);
    this.handleLongrunBack= this.handleLongrunBack.bind(this);
    this.getBests = this.getBests.bind(this);
		this.virtualLoaderRef = React.createRef();
		this.requestCache = [];
	}

  // new

	getItemData = memoize(
    ( calItems, initDate, fct ) => ({calItems, initDate, fct})
    )
	
  //

	componentDidMount() {
    //this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
	  this.getBests();
    this.getUser();
    this.getSummary();
	}  
	
  componentWillUnmount() {
    //clearInterval(this.interval);
  }

  getBests() {
	 	const { match: { params } } = this.props;
	  const today = moment(this.state.initDate).format('YYYY-MM-DD');
    const bests = this.state.bests;
    const bestsYear = this.state.bestsYear;
    const bestsAll = this.state.bestsAll;
    axios 
        .get(`/api/v1/athletes/${params.id}/activities/?start_day=${today}&end_day=${today}&today=${today}`)    
          .then(response => response.data)
          .then(data => {
            data.bests.forEach((item, index) => {
              bests[index] = item;
              });     
            data.bestsYear.forEach((item, index) => {
              bestsYear[index] = item;
              }); 
            data.bestsAll.forEach((item, index) => {
              bestsAll[index] = item;
              });        
              this.setState({ bests: bests, bestsYear: bestsYear, bestsAll: bestsAll });
              this.setState({ startMonth: data.startMonth, startYear: data.startYear, startYearAll: data.startYearAll, distUnit: data.distUnit})
          })
          .catch(error => {
            this.setState({redirect: true});
            console.log(error);
          });
	}

  getSummary() {
    const modifiers = this.state.modifiers;
    axios 
        .get(`/api/v1/activities/summary`)
        .then(response => response.data) 
        .then(data => {
            data.dates.forEach((date, index) => {
             
                var day = new Date(new Date(date).setHours(0,0,0,0));
                if (data.types[index] == 0 ) {
                  var indexDay = modifiers.run.map(Number).indexOf(+day);
                  if (indexDay === -1) { modifiers.run.push(day); modifiers.run.sort((a,b) => {return b-a});} 
                }
                if (data.types[index] == 1) {
                  var indexDay = modifiers.race.map(Number).indexOf(+day);
                  if (indexDay === -1) { modifiers.race.push(day); modifiers.race.sort((a,b) => {return b-a});} 
                }
                if (data.types[index] == 2) {
                  var indexDay = modifiers.longrun.map(Number).indexOf(+day);
                  if (indexDay === -1) { modifiers.longrun.push(day); modifiers.longrun.sort((a,b) => {return b-a});} 
                }
                if (data.types[index] == 3) {
                  var indexDay = modifiers.workout.map(Number).indexOf(+day);
                  if (indexDay === -1) { modifiers.workout.push(day); modifiers.workout.sort((a,b) => {return b-a});} 
                }
  
                });
            this.setState({ modifiers });
        });          
  }

  getUser() {
    const { match: { params } } = this.props;
    axios 
        .get(`/api/v1/athletes/${params.id}`)
        .then(response => response.data) 
        .then(data => {
          this.setState({ userData: data.userData });
          localStorage.setItem("loggedIn", data.userData.user);
        })
        .catch(error => {
            this.setState({redirect: true});
            console.log(error);
        });
  }


  	loadMoreItems(visibleStartIndex, visibleStopIndex) {
      // increase stop index to show more data in cal boxes
      visibleStartIndex = Math.max(0,visibleStartIndex - 46);
  		return new Promise((resolve, reject) => {
  			const { match: { params } } = this.props;
  			const key = [visibleStartIndex, visibleStopIndex].join(":");
  			//console.log('visibleStartIndex: ' + visibleStartIndex + 'visibleStopIndex: ' + visibleStopIndex); 
			if (this.state.requestCache[key]) {
  				return
			}	
			const length = visibleStopIndex - visibleStartIndex;
			const visibleRange = [...Array(length).keys()].map(
  				x => x + visibleStartIndex
			);
			const itemsRetrieved = visibleRange.every(index => !!this.state.calItems[index]);

			if (itemsRetrieved) {
				const requestCache = this.state.requestCache
  				requestCache[key] = key
  				this.setState({ requestCache }); 
  			return
			}
			const today = moment(this.state.initDate).format('YYYY-MM-DD');
			const endDate = moment(this.state.initDate).subtract(visibleStartIndex,'days').format('YYYY-MM-DD');
			const startDate = moment(this.state.initDate).subtract(visibleStartIndex+length, 'days').format('YYYY-MM-DD');
			const calItems = this.state.calItems;
			const modifiers = this.state.modifiers;

      const cutoff = 99.0;

      //const startYear = this.state.startYear;
			//console.log('modifiers.run: ' + modifiers.run);
			axios 
				.get(`/api/v1/athletes/${params.id}/activities/?start_day=${startDate}&end_day=${endDate}&today=${today}`)    
	  			.then(response => response.data)
	  			//.then(data => {console.log('data: ' + data.activities)})
  				.then(data => {
    				data.activities.forEach((item, index) => {
      				calItems[index + visibleStartIndex] = item;
      				// if (item.runs) {item.runs.forEach(run => { 
              //   if ( run.distance > cutoff ) {
      				// 	var day = new Date(new Date(run.start_date_local).setHours(0,0,0,0));
      				// 	if (run.workout_type_id == 0 ) {
      				// 		var indexDay = modifiers.run.map(Number).indexOf(+day);
      				// 		if (indexDay === -1) { modifiers.run.push(day); modifiers.run.sort((a,b) => {return b-a});} 
      				// 	}
      				// 	if (run.workout_type_id == 1) {
      				// 		var indexDay = modifiers.race.map(Number).indexOf(+day);
      				// 		if (indexDay === -1) { modifiers.race.push(day); modifiers.race.sort((a,b) => {return b-a});} 
      				// 	}
      				// 	if (run.workout_type_id == 2) {
      				// 		var indexDay = modifiers.longrun.map(Number).indexOf(+day);
      				// 		if (indexDay === -1) { modifiers.longrun.push(day); modifiers.longrun.sort((a,b) => {return b-a});} 
      				// 	}
      				// 	if (run.workout_type_id == 3) {
      				// 		var indexDay = modifiers.workout.map(Number).indexOf(+day);
      				// 		if (indexDay === -1) { modifiers.workout.push(day); modifiers.workout.sort((a,b) => {return b-a});} 
      				// 	}
      				// 	}
              //   });
      				// }
      				});          
      				this.setState({ calItems });
      				resolve(); 
    			})
  				.catch(error => {
            this.setState({redirect: true});
  					console.log(error);
  				});
  			});
	  };

  	render() {

      if (redirect) {
       return <Redirect to='/errors/401'/>;
      }

      if(!this.state.userData){
        return null; // add a spinner or something until the posts are loaded
      }

      const { match: { params } } = this.props;

      // colors: no best, run, race, long, workout, real race
      const color = [ "rgb(0,0,0)","#607d8b", "#e91e63", "#03a9f4", "#9c27b0" , "#e91e63"];
  		const { redirect, selectedDay, calItems, modifiers, bests, distUnit, startMonth, 
        startYear, startYearAll, bestsYear, bestsAll, userData, bestsOpen, ActivityEditId,
        ActivityEditOpen, ActivityEditName, ActivityEditWotid, ActivityEditBests, ActivityEditexternalID, initDate } = this.state;
  		 

      const fct = this.fct

      const currentYear = moment(initDate).toDate().getFullYear();
  		const currentMonth = moment(initDate).toDate().getMonth();
  		const fromMonth = new Date(startYearAll, 0);
  		const toMonth = new Date(currentYear, currentMonth);
      const itemCount = moment(initDate).diff(moment(fromMonth),'days')+1;
      const dayHeight = 130;
     	//const mo = moment(selectedDay,"YYYY-M-D");
      	//const daysFloat = moment.duration(mo.diff(moment().startOf('day'))).asDays();
      	//const days = Math.round(-moment.duration(mo.diff(moment().startOf('day'))).asDays());
      const isItemLoaded = ({ index }) => !!calItems[index]

      var athleteName = "";
      var imageUrl = "";
      var scheduledUploads="";
      var total_runs="";
      const nMonths = bests.length;
      const nYears = bestsYear.length;
      //var yearBests = [];
      var monthBests = null;
      var yearBests = {};
      yearBests["paces"] = [];
      yearBests["types"] = [];
      yearBests["activityIds"] = [];
      yearBests["dates"] = [];
      var allBests = {};
      allBests["paces"] = [];
      allBests["types"] = [];
      allBests["activityIds"] = [];
      allBests["dates"] = [];
      if (startMonth !== null && bests !== []) {
        var month = 12*selectedDay.getFullYear() + selectedDay.getMonth() + 1 - startMonth;
        if (month >= 0 && month < nMonths) {
          monthBests = bests[month];
        }
      }
      if (startYear !== null && bestsYear !== []) {
        var year = selectedDay.getFullYear() - startYear;
        if (year >= 0 && year < nYears) {
          bestsYear[year].map((mId,dist) => 
            {yearBests["paces"].push(bests[mId].paces[dist]);
            yearBests["types"].push(bests[mId].types[dist]);
            yearBests["activityIds"].push(bests[mId].activityIds[dist]);
            yearBests["dates"].push(bests[mId].dates[dist]);}
            );
        }
      }
      if (bestsAll !== []) {
        bestsAll.map((mId,dist) =>
          {allBests["paces"].push(bests[mId].paces[dist]);
          allBests["types"].push(bests[mId].types[dist]);
          allBests["activityIds"].push(bests[mId].activityIds[dist]);
          allBests["dates"].push(bests[mId].dates[dist]);}
          );
      }
      if (userData !== null) {
        athleteName = userData.fullname
        imageUrl = userData.image_url
        total_runs = userData.total_runs
        scheduledUploads = userData.scheduledUploads
      }
    

    	return (
    		<Fragment>
        <ThemeProvider theme={theme}>
        <div>
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap" rel="stylesheet" /> 
        </div>

        <div>
        <ActivityEdit
            id={ActivityEditId}
            open={ActivityEditOpen}
            name={ActivityEditName}
            workoutTypeId={ActivityEditWotid}
            bests={ActivityEditBests}
            open={ActivityEditOpen}
            externalID={ActivityEditexternalID}
            handleClose={this.handleCloseActivityEdit}
        />
        </div>


        <Header userId = {params.id} userName = {athleteName} imageUrl = {imageUrl} />
        <div style={{marginTop: 80, marginLeft:5, marginRight:5}} >
    		<Grid 
  			container  
        spacing={0}
  			direction="row"
  			justifyContent="center"
  			alignItems="flex-start"
			  >
				<Grid item xs={2} align="left">
        <div>

        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >

        <Grid item  align="left">
        <div>
        <Typography variant="h5" gutterBottom>
				Timeline
    		</Typography>
    		<Typography variant="h6" gutterBottom>
				<Box fontWeight="fontWeightBold">
    			<YearMonthForm
              		startYear={startYearAll}
                  date={selectedDay}
                  initDate={initDate}
              		onChange={this.handleYearMonthChange}
            	/>
    		</Box>
    		</Typography> 
        </div>
        </Grid>

        <Grid item  align="center">
        <div>

        <Stack alignItems="center" justifyContent="center">
        <Stack direction = "row">
        <Button 
          style={{ minWidth: 10, minHeight: 10 }}
          onClick={this.handleRaceBack} >
          <FontAwesomeIcon icon={faChevronLeft} style={{color: "gray"}} />
        </Button>
        <Typography sx={{ color: color[2] }}>
        Race
        </Typography>
        <Button 
          style={{ minWidth: 10, minHeight: 10 }}
          onClick={this.handleRaceForward} >
          <FontAwesomeIcon icon={faChevronRight} style={{color: "gray"}} />
        </Button>
        </Stack>

        <Stack direction = "row">
        <Button 
          style={{ minWidth: 10, minHeight: 10 }}
          onClick={this.handleWorkoutBack} >
          <FontAwesomeIcon icon={faChevronLeft} style={{color: "gray"}} />
        </Button>
        <Typography sx={{ color: color[4] }}>
        Workout
        </Typography>
        <Button 
          style={{ minWidth: 10, minHeight: 10 }}
          onClick={this.handleWorkoutForward} >
          <FontAwesomeIcon icon={faChevronRight} style={{color: "gray"}} />
        </Button>
        </Stack>

        <Stack direction = "row">
        <Button 
          style={{ minWidth: 10, minHeight: 10 }}
          onClick={this.handleLongrunBack} >
          <FontAwesomeIcon icon={faChevronLeft} style={{color: "gray"}} />
        </Button>
        <Typography sx={{ color: color[3] }}>
        Long Run
        </Typography>
        <Button 
          style={{ minWidth: 10, minHeight: 10 }}
          onClick={this.handleLongrunForward} >
          <FontAwesomeIcon icon={faChevronRight} style={{color: "gray"}} />
        </Button>
        </Stack>
        </Stack>

        </div>
        </Grid>
        </Grid>

        </div>
        </Grid>
    		<Grid item xs={7} align="center">
        	<CalBox 
            open={open}
            selectedDay={selectedDay}
            handleDayClick={this.handleDayClick}
            fromMonth={fromMonth}
            initDate={initDate}
            modifiers={modifiers}
          />		
      	</Grid>
        <Grid item xs={3} align="right">
            
          <PerformanceBox 
            selectedDay={selectedDay}
            calItems={calItems}
            initDate={initDate}
          />
            
          </Grid>	
      		</Grid>	
          </div>

          
          <BestsBox 
            distUnit={distUnit} 
            allBests={allBests} 
            yearBests={yearBests} 
            monthBests={monthBests} 
            totalRuns={total_runs}
            scheduledUploads={scheduledUploads}
            onChange={this.handleYearMonthChange}
          />

        
          <Grid  item xs={12} style={{marginLeft:5, marginRight:5}}>
      		<InfiniteLoader
      				ref={this.virtualLoaderRef}
      				isItemLoaded={isItemLoaded}
      				loadMoreItems={this.loadMoreItems}
      				itemCount={itemCount}
      				minimumBatchSize={92}
    			>
      				{({ onItemsRendered, ref }) => (
          <AutoSizer disableHeight>   
            {({ width }) => {  
              const itemData = this.getItemData(
                calItems,
                initDate,
                fct
                )

            return(
            <div>
  				  <List
  					 ref={ref}
  					 width={width}
  					 height={5*dayHeight}
  					 itemData={itemData}
      			 itemCount={itemCount}
      			 itemSize={dayHeight}
      			 outerElementType={this.outerElementType}
      			 onItemsRendered={onItemsRendered}
  				  >
  					{ItemRenderer}
  				  </List>
            </div>
            )
            }}
          </AutoSizer>
  				)}
    			</InfiniteLoader>
          </Grid>

          </ThemeProvider>
  			</Fragment>
    		);
  	}

    //

    


    handleCloseActivityEdit(action,id,newName,newId,bestsStatus) {

      const { match: { params } } = this.props;

      this.setState({ 
        ActivityEditOpen: false,
      });

      if ( action === 'delete') {

        // front-end does not update bests directly, reload needed since new bests need to be found (server).
   
        const workout_type_id = this.state.ActivityEditWotid; // old id

        const modifiers = this.state.modifiers;
        const calItems = this.state.calItems;
        const item = calItems[this.state.ActivityEditItemIndex];

        item.runs.splice(this.state.ActivityEditIndex,1);
        item.computed.splice(this.state.ActivityEditIndex,1);
        item.labels.splice(this.state.ActivityEditIndex,1);
        item.num_runs =  item.num_runs - 1;

        calItems[this.state.ActivityEditItemIndex] = item;
        this.setState({
          calItems
        });
      
        // check which workout types are left on that day

        const type0 = item.runs.findIndex(r => r.workout_type_id === 0);
        const type1 = item.runs.findIndex(r => r.workout_type_id === 1);
        const type2 = item.runs.findIndex(r => r.workout_type_id === 2);
        const type3 = item.runs.findIndex(r => r.workout_type_id === 3);

        var day = new Date(new Date(this.state.ActivityEditDate).setHours(0,0,0,0));

        if (workout_type_id == 0) {
          var indexDay = modifiers.run.map(Number).indexOf(+day);
          if (indexDay !== -1 && type0 === -1) { modifiers.run.splice(indexDay,1); modifiers.run.sort((a,b) => {return b-a}); } 
        }
        if (workout_type_id == 1) {
          var indexDay = modifiers.race.map(Number).indexOf(+day);
        if (indexDay !== -1 && type1 === -1) { modifiers.race.splice(indexDay,1); modifiers.race.sort((a,b) => {return b-a});} 
        }
        if (workout_type_id == 2) {
          var indexDay = modifiers.longrun.map(Number).indexOf(+day);
          if (indexDay !== -1 && type2 === -1) { modifiers.longrun.splice(indexDay,1); modifiers.longrun.sort((a,b) => {return b-a}); } 
        }
        if (workout_type_id == 3) {
          var indexDay = modifiers.workout.map(Number).indexOf(+day);
          if (indexDay !== -1 && type3 === -1) { modifiers.workout.splice(indexDay,1); modifiers.workout.sort((a,b) => {return b-a});} 
        }

        this.setState({ 
          modifiers
        });

        // update weekly and monthly totals (contained in calItmes item)

        const today = moment(this.state.initDat).format('YYYY-MM-DD');
        const endDate = moment.min([moment.max([
          moment(this.state.ActivityEditDate).endOf('month'),
          moment(this.state.ActivityEditDate).add(7,'days')]),
          moment(this.state.initDat)
          ]).format('YYYY-MM-DD');
        const startDate = moment(this.state.ActivityEditDate).subtract(1,'days').format('YYYY-MM-DD');
        const StartIndex = this.state.ActivityEditItemIndex + 1;

        axios
        .delete(`/api/v1/activities/destroy/${id}`)    
        .then(response => response.status)
        .then(status => {
          if (status == 204) {
            this.getBests();
            axios
              .get(`/api/v1/athletes/${params.id}/activities/?start_day=${startDate}&end_day=${endDate}&today=${today}`) 
              .then(response2 => response2.data)
              .then(data => {
                  const length = data.activities.length;
                  data.activities.forEach((row, index) => {
                    calItems[ - length + index + StartIndex + 1 ] = row;  
                    this.setState({ 
                      calItems
                    });
                  });        
              })
              .catch(error => {
                console.log(error);
              });
          }
        })
        .catch(error => {
          console.log(error);
        });

      }

      if ( action === 'ok') {
       
        const workout_type_id = this.state.ActivityEditWotid; // old id
        const bests_old = this.state.ActivityEditBests;  

        if ( bestsStatus != bests_old ) {

          if (bestsStatus == false) {

            axios.delete(`/api/bests/destroy/${id}`)
              .then(response => response.status)
              .then(status => {
                if ( status == 204 ) {
                  this.getBests();
              }
              })    
              .catch(error => {
                console.log(error);
              });  
           
          }

          if (bestsStatus == true) {

            axios.post(`/api/bests/activate/${id}`)
              .then(response => response.status)
              .then(status => {
                if ( status == 200 ) {
                  this.getBests();
                }
              })    
              .catch(error => {
                console.log(error);
              });  

          }

        }  

      const modifiers = this.state.modifiers;
      const calItems = this.state.calItems;
      //let items = [...this.state.calItems];
      //let item = {...items[this.state.ActivityEditItemIndex]};

      const item = calItems[this.state.ActivityEditItemIndex];

      item.runs[this.state.ActivityEditIndex].name = newName;
      item.runs[this.state.ActivityEditIndex].workout_type_id = newId

      //items[this.state.ActivityEditItemIndex] = item;

      calItems[this.state.ActivityEditItemIndex] = item;

      this.setState({
          calItems
      });

      // update season bests and
      // trophy update in case of race (this information comes from calItems item)

      const today = moment(this.state.initDat).format('YYYY-MM-DD');
      const startDate = moment(this.state.ActivityEditDate).subtract(1,'days').format('YYYY-MM-DD');
      const endDate = moment(this.state.ActivityEditDate).format('YYYY-MM-DD');
      const StartIndex = this.state.ActivityEditItemIndex + 1;

      axios
        .post(`/api/v1/activities/edit?activity_id=${id}&name=${newName}&workout_type_id=${newId}`)    
        .then(response => response.status)
        .then(status => {
          if (status == 200) {
            this.getBests();
            axios
              .get(`/api/v1/athletes/${params.id}/activities/?start_day=${startDate}&end_day=${endDate}&today=${today}`) 
              .then(response2 => response2.data)
              .then(data => {
                  const length = data.activities.length;
                  data.activities.forEach((row, index) => {
                    calItems[ - length + index + StartIndex + 1 ] = row;  
                    this.setState({ 
                      calItems
                    });
                  });        
              })
              .catch(error => {
                console.log(error);
              });
          }
        })
        .catch(error => {
          console.log(error);
        });

      // update modififiers for calendar box   
      // check which workout types are left on that day

      const type0 = item.runs.findIndex(r => r.workout_type_id === 0);
      const type1 = item.runs.findIndex(r => r.workout_type_id === 1);
      const type2 = item.runs.findIndex(r => r.workout_type_id === 2);
      const type3 = item.runs.findIndex(r => r.workout_type_id === 3);

      var day = new Date(new Date(this.state.ActivityEditDate).setHours(0,0,0,0));

      if (workout_type_id == 0 && type0 === -1) {
        var indexDay = modifiers.run.map(Number).indexOf(+day);
        if (indexDay !== -1) { modifiers.run.splice(indexDay,1); modifiers.run.sort((a,b) => {return b-a});} 
      }
      if (workout_type_id == 1 && type1 === -1) {
        var indexDay = modifiers.race.map(Number).indexOf(+day);
        //console.log("indexDay: " + indexDay);
        if (indexDay !== -1) { modifiers.race.splice(indexDay,1); modifiers.race.sort((a,b) => {return b-a});} 
      }
      if (workout_type_id == 2 && type2 === -1) {
        var indexDay = modifiers.longrun.map(Number).indexOf(+day);
        if (indexDay !== -1) { modifiers.longrun.splice(indexDay,1); modifiers.longrun.sort((a,b) => {return b-a});} 
      }
      if (workout_type_id == 3 && type3 === -1) {
        var indexDay = modifiers.workout.map(Number).indexOf(+day);
        if (indexDay !== -1) { modifiers.workout.splice(indexDay,1); modifiers.workout.sort((a,b) => {return b-a});} 
      }

      if (newId == 0) {
        var indexDay = modifiers.run.map(Number).indexOf(+day);
        if (indexDay === -1) { modifiers.run.push(day); modifiers.run.sort((a,b) => {return b-a});} 
      }
      if (newId == 1) {
        var indexDay = modifiers.race.map(Number).indexOf(+day);
        if (indexDay === -1) { modifiers.race.push(day); modifiers.race.sort((a,b) => {return b-a});} 
      }
      if (newId == 2) {
        var indexDay = modifiers.longrun.map(Number).indexOf(+day);
        if (indexDay === -1) { modifiers.longrun.push(day); modifiers.longrun.sort((a,b) => {return b-a});} 
      }
      if (newId == 3) {
        var indexDay = modifiers.workout.map(Number).indexOf(+day);
        if (indexDay === -1) { modifiers.workout.push(day); modifiers.workout.sort((a,b) => {return b-a});} 
      }

      //console.log("modifiers: " + JSON.stringify(modifiers));

      this.setState({ 
        modifiers
      });

      //console.log("modifiers: " + JSON.stringify(this.state.modifiers));

    }

  }

  	handleYearMonthChange(month) {
  		const mo = moment(month,"YYYY-M-D").startOf('day');
  		const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
    	this.setState({ selectedDay: mo.toDate() });
    	this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');

  	}

  	handleDayClick(day, modifiers) {
      const { startYearAll } = this.state;
      const year = moment(day,"YYYY-M-D").year();
      const endYear = moment(this.state.initDate).year();
      if (year >= startYearAll && year <= endYear) {
  		  const mo = moment(day,"YYYY-M-D");
  		  const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
    	  this.setState({ 
      		  selectedDay:  moment(day,"YYYY-M-D").startOf('day').toDate()
    	  });
    	  this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');
      }
  	}

    handleRaceBack() {
      const index = this.state.modifiers.race.findIndex((e) => { return e < this.state.selectedDay });
      if ( index != -1 ) {
        const day = this.state.modifiers.race[index];
        const mo = moment(day);
        const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
        this.setState({ 
          selectedDay:  day
        });
        this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');
      }
    }

    handleRaceForward() {
      const index = this.state.modifiers.race.findLastIndex((e) => { return e > this.state.selectedDay });
      if ( index != -1 ) {
        const day = this.state.modifiers.race[index];
        const mo = moment(day);
        const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
        this.setState({ 
          selectedDay:  day
        });
        this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');
      }
    }

    handleWorkoutBack() {
      const index = this.state.modifiers.workout.findIndex((e) => { return e < this.state.selectedDay });
      if ( index != -1 ) {
        const day = this.state.modifiers.workout[index];
        const mo = moment(day);
        const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
        this.setState({ 
          selectedDay:  day
        });
        this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');
      }
    }

    handleWorkoutForward() {
      const index = this.state.modifiers.workout.findLastIndex((e) => { return e > this.state.selectedDay });
      if ( index != -1 ) {
        const day = this.state.modifiers.workout[index];
        const mo = moment(day);
        const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
        this.setState({ 
          selectedDay:  day
        });
        this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');
      }
    }

    handleLongrunBack() {
      const index = this.state.modifiers.longrun.findIndex((e) => { return e < this.state.selectedDay });
      if ( index != -1 ) {
        const day = this.state.modifiers.longrun[index];
        const mo = moment(day);
        const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
        this.setState({ 
          selectedDay:  day
        });
        this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');
      }
    }

    handleLongrunForward() {
      const index = this.state.modifiers.longrun.findLastIndex((e) => { return e > this.state.selectedDay });
      if ( index != -1 ) {
        const day = this.state.modifiers.longrun[index];
        const mo = moment(day);
        const days = Math.round(-moment.duration(mo.diff(moment(this.state.initDate).startOf('day'))).asDays());
        this.setState({ 
          selectedDay:  day
        });
        this.virtualLoaderRef.current._listRef.scrollToItem(days, 'start');
      }
    }

    //


    fct(itemIndex,index) {
      //console.log("fct called: indices = " + itemIndex + " " + index);
      
      let items = [...this.state.calItems];
      let item = {...items[itemIndex]};
      var workout_type_id = item.runs[index].workout_type_id;
      var name = item.runs[index].name;
      var date = item.runs[index].start_date_local;
      var bests = item.runs[index].bests_activated;
      var id = item.runs[index].id;
      var external_id = item.runs[index].external_id;

      console.log("Date/index: " + date + "/" + index + " ID: " + id + " extID: " + external_id);

      this.setState({
        ActivityEditId: id,
        ActivityEditexternalID: external_id,
        ActivityEditName: name,
        ActivityEditWotid: workout_type_id,
        ActivityEditDate: date,
        ActivityEditBests: bests,
        ActivityEditItemIndex: itemIndex,
        ActivityEditIndex: index,
        ActivityEditOpen: true
      });

    }

  	handleOnWheel({ currentTarget, deltaY, clientY }) {
      let dayHeight = 130;
  		let visibleRowIndex = currentTarget.scrollTop / dayHeight;
  		//let totalHeight = currentTarget.clientHeight;
  		//let itemHeight = 130;
  		//let numberOfItemsVisible = visibleRowIndex + totalHeight / itemHeight;
  
  		//console.log("visibility is from :" + visibleRowIndex);
  		//console.log("to :" + numberOfItemsVisible);

  		const day = moment(this.state.initDate).subtract(Math.round(visibleRowIndex), 'days').startOf('day').toDate()

  		this.setState({ 
      		selectedDay:  day
    	});
	}


	outerElementType = forwardRef((props, ref) => (
  		<div ref={ref} onWheel={event => this.handleOnWheel(event, props)} {...props} />
	));



}


function ActivityEdit(props) {
  const { id, open, name, workoutTypeId, bests, handleClose, externalID } = props;
  const options = [
    "Run",
    "Race",
    "Long Run",
    "Workout"
    ];
  
  const color = [ "#607d8b", "#e91e63", "#03a9f4", "#9c27b0" ];
  
  const [state, setState] = React.useState({ value: workoutTypeId, nameNew: name, bestsActive: bests});

  const { value, nameNew, bestsActive } = state;

  //console.log("value: " + value);

  React.useEffect(() => {
    if (open) {
      setState((prevState) => ({...prevState, value: workoutTypeId, nameNew: name, bestsActive: bests}));
    }
  }, [workoutTypeId, name, open, bests]);


  const handleChange = (event) => {
    var type_id = options.findIndex(obj => obj === event.target.value);
    setState((prevState) => ({...prevState, value: type_id}));
    //console.log("selected type value/id: " + event.target.value + " / " + type_id);
  };
  
  const handleChangeBests = (event) => {
    setState((prevState) => ({...prevState, bestsActive: event.target.checked}));
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleOk = () => {
    handleClose("ok",id,nameNew,value,bestsActive);
  };

  const handleDelete = () => {
    handleClose("delete",id,nameNew,value,bestsActive);
  };

  return(
  <Dialog open={open} onClose={handleCancel}>
    <DialogTitle>Edit Activity</DialogTitle>
      <DialogContent>
        <DialogContentText>
            Here you can change the name of the run and its type.  
        </DialogContentText>
        <TextField
          margin="dense"
          id="ActivityEditName"
          value={nameNew}
          onChange={(event) => {
            setState((prevState) => ({...prevState, nameNew: event.target.value}));
          }}
          label="Name"
          fullWidth
          variant="standard"
        />
        <RadioGroup
          row
          value={options[value]}
          onChange={handleChange}
        >
          {options.map((option,j) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio 
                sx={{
                  color: color[j],
                  '&.Mui-checked': {
                    color: color[j],
                  },
                }}
              />}
              label={option}
            />
          ))}
        </RadioGroup>
        <Divider />
        <Stack direction="row"  justifyContent="space-between">
        <FormControlLabel 
          control={<Checkbox  
            checked={bestsActive}
            onChange={handleChangeBests}
            inputProps={{ 'aria-label': 'controlled' }}
          />} 
          label="Bests enabled"
          sx={{ width: 1/2 }} 
        />
        <DialogContentText variant="caption" sx={{ width: 1/2 }}>
            You can disable the automatically estimated bests for this run. 
            Your season bests will be updated. 
        </DialogContentText>
        </Stack>
        <Divider />
        <Stack direction="row"  justifyContent="flex-start">
        <DialogContentText sx={{ width: 1/2 }}>
            Go to your run on Garmin: 
        </DialogContentText>
        <Link
        href={`https://connect.garmin.com/modern/activity/${externalID}`}
        target="_blank" 
        rel="noreferrer" 
        variant="body2"
        sx={{ width: 1/2 }}
        >
        connect.garmin.com/modern/activity/{externalID} 
        </Link>
        </Stack>
      </DialogContent>
        <Stack direction="row" justifyContent="space-between" >
        <DialogActions>
        <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
        <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleOk}>OK</Button>
        </DialogActions>
        </Stack>
  </Dialog>
  );
}



function CalBox({selectedDay,handleDayClick,fromMonth,initDate,modifiers}) {

  const width = useWindowSize().width;

  const selectedStyle = { backgroundColor: 'white', border: '4px solid', color: '#ffb300' };
  const runStyle = { backgroundColor: '#607d8b', color: 'white' };
  const longrunStyle = { backgroundColor: '#03a9f4', color: 'white' };
  const workoutStyle = { backgroundColor: '#9c27b0', color: 'white' };
  const raceStyle = { backgroundColor: '#e91e63', color: 'white' };
  const runSelectedStyle = { backgroundColor: '#607d8b', border: '4px solid', color: '#ffb300' };
  const longrunSelectedStyle = { backgroundColor: '#03a9f4', border: '4px solid', color: '#ffb300' };
  const workoutSelectedStyle = { backgroundColor: '#9c27b0', border: '4px solid', color: '#ffb300' };
  const raceSelectedStyle = { backgroundColor: '#e91e63', border: '4px solid', color: '#ffb300' };

  if (modifiers.run.map(Number).indexOf(+selectedDay) !== -1) {
    modifiers.runSelected = [selectedDay];
  } else {
    modifiers.runSelected = [ ];
  }

  if (modifiers.longrun.map(Number).indexOf(+selectedDay) !== -1) {
    modifiers.longrunSelected = [selectedDay];
  } else {
    modifiers.longrunSelected = [ ];
  }

  if (modifiers.workout.map(Number).indexOf(+selectedDay) !== -1) {
    modifiers.workoutSelected = [selectedDay];
  } else {
    modifiers.workoutSelected = [ ];
  }

  if (modifiers.race.map(Number).indexOf(+selectedDay) !== -1) {
    modifiers.raceSelected = [selectedDay];
  } else {
    modifiers.raceSelected = [ ];
  }  


  let numberOfMonths;
  let subtractMonth;

  if ( width <= 950) {
    numberOfMonths = 1;
    subtractMonth = 0;
  } else if ( width > 950 && width <=1300) {
    numberOfMonths = 2;
    subtractMonth = 1;
  } else if ( width > 1300) {
    numberOfMonths = 3;
    subtractMonth = 1;
  }



  return(
    <ThemeProvider theme={theme}>
    <DayPicker
      mode="single"
      selected={selectedDay}
      onDayClick={handleDayClick} 
      numberOfMonths={numberOfMonths}
      month={moment(selectedDay,"YYYY-M-D").subtract(subtractMonth, 'months').toDate()}
      disabled={[{after: initDate, before: fromMonth}]}
      //showWeekNumber
      modifiers={modifiers}
      modifiersStyles={{ selected: selectedStyle, 
        run: runStyle, 
        race: raceStyle, 
        workout: workoutStyle, 
        longrun: longrunStyle, 
        runSelected: runSelectedStyle,
        longrunSelected: longrunSelectedStyle,
        workoutSelected: workoutSelectedStyle,
        raceSelected: raceSelectedStyle  
      }}
      weekStartsOn={1}
      pagedNavigation
      disableNavigation={true}
      enableOutsideDaysClick={false}
    />  
    </ThemeProvider>
  )

}

//

function PerformanceBox({selectedDay,calItems,initDate}) {
  
  const mo = moment(selectedDay,"YYYY-M-D").startOf('day');
  const days = Math.round(-moment.duration(mo.diff(moment(initDate).startOf('day'))).asDays());
  const item = calItems[days];
  const vm_form = item ? (item.analysis ? item.analysis.vm_form : '---') : '---'
  const tc_form = item ? (item.analysis ? item.analysis.tc_form : '---') : '---'
  const Es = item ? (item.analysis ? item.analysis.Es.toFixed(2) : '---') : '---'
  const El = item ? (item.analysis ? item.analysis.El.toFixed(2) : '---') : '---'

  //console.log("mo :" + mo);
  //console.log("days :" + days);
  //console.log("before round :" + moment.duration(mo.diff(moment().startOf('day'))).asDays());

  return(
    <ThemeProvider theme={theme}>
    <div>
    <Typography variant="h5" gutterBottom>
      Performance
    </Typography>
    <Box  
      display="block" 
      width={180}   
      alignItems="center" 
      justifyContent="center" 
      border={1} borderRadius={5} 
      borderColor="lightgray" >

      <TableContainer>
        <Table size="small">
            <TableBody>
                <TableRow>
                  <TableCell> pace @ VO2max </TableCell>
                  <TableCell style={{ width: 50 }} > {vm_form} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> time @ VO2max </TableCell>
                  <TableCell style={{ width: 50 }} > {tc_form} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> anaer. Endurance </TableCell>
                  <TableCell style={{ width: 50 }} > {Es} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> aer. Endurance </TableCell>
                  <TableCell style={{ width: 50 }} > {El} </TableCell>
                </TableRow>
          </TableBody>
        </Table>  
      </TableContainer>

    </Box>
    </div>
    </ThemeProvider>
  )
}

//

function BestsBox({distUnit,allBests,yearBests,monthBests,totalRuns,scheduledUploads,onChange}) {

  const color = [ "rgb(0,0,0)","#607d8b", "#e91e63", "#03a9f4", "#9c27b0" , "#e91e63"];
  
  const classes = useStyles();

  let uploads;

  if (scheduledUploads > 0) {
    uploads = true;
  } else {
    uploads = false;
  }

  const [state, setState] = useState({
    bestsOpen: false,
    toastOpen: uploads,
  });

  const { bestsOpen, toastOpen } = state;

  const handleClose = (event, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          setState((prevState) => ({ ...prevState, toastOpen: false }));
      
  };   

  

  const displayNoBests = () => {
      const handleBestsOpen = () =>
       setState((prevState) => ({ ...prevState, bestsOpen: !bestsOpen }));

          return(
          <div>  
          <Snackbar open={toastOpen} onClose={handleClose} >
            <Alert variant="filled" onClose={handleClose} severity="warning">
              <AlertTitle> New User </AlertTitle>
              It seems that your are a new user. Welcome to Sciendure! 
              Currently your runs from Garmin are uploaded to Sciendure. This might take a little time.
              Please come back soon to check the progress. <strong>{totalRuns}</strong> runs have been uploaded, 
              and <strong>{scheduledUploads}</strong> runs are still waiting for upload.
            </Alert>
          </Snackbar>
          <Box display="block" border={1} borderRadius={5} borderColor="lightgray" >
            <div style={{
                  width: 160,  
                  left: 20,
                  backgroundColor: "#FAFAFA",
                  position: "relative",
                  zIndex: 0
                  }} >
            <Box mt={-2} ml={1} sx={{ flexWrap: 'nowrap' }} >
            <Typography variant="caption" color="inherit" >
            Season Bests
            </Typography>
            <Button 
              onClick={handleBestsOpen}
              style={{padding: 10, minWidth: 10, minHeight: 10 }} >
              <FontAwesomeIcon icon={faChevronDown} style={{color: "gray"}} />
            </Button>
            </Box>
            </div>
            </Box>
            </div>
            );
    };



  const displayBests = ({distUnit,allBests,yearBests,monthBests}) => {
      const handleBestsOpen = () =>
      setState((prevState) => ({ ...prevState, bestsOpen: !bestsOpen }));

      //
      //console.log("yearBests: " + JSON.stringify(yearBests) + " monthBests: " + JSON.stringify(monthBests));

      const row1 = [<TableCell key="all-coll-title">All Time</TableCell>];
      const row2 = [<TableCell key="year-coll-title">Year</TableCell>];
      const row3 = [<TableCell key="month-coll-title">Month</TableCell>];

      if (allBests) {
        if (allBests.paces.length > 0) {
           row1.push(allBests.paces.map((pace,i) => (
            allBests.types[i] == 4 ?
                <TableCell key={`all-col-${i}`} align="right" style={{color: color[allBests.types[i]+1], cursor: 'pointer'}} onClick={ () => onChange(new Date(allBests.dates[i])) } ><FontAwesomeIcon icon={faTrophy} />{pace}</TableCell>
                :
                allBests.types[i] == -1 ?
                  <TableCell key={`all-col-${i}`} align="right" style={{color: color[allBests.types[i]+1] }}> --:-- </TableCell>
                  :
                  <TableCell key={`all-col-${i}`} align="right" style={{color: color[allBests.types[i]+1], cursor: 'pointer'}} onClick={ () => onChange(new Date(allBests.dates[i])) } >{pace}</TableCell>
          )));
        } else {
          row1.push([...Array(17)].map((v,i) => (<TableCell key={`all-col-${i}`} align="right"> --:-- </TableCell>)));
        }
      } else {
        row1.push([...Array(17)].map((v,i) => (<TableCell key={`all-col-${i}`} align="right"> --:-- </TableCell>)));
      }        

      if (yearBests) {
        if (yearBests.paces.length > 0) {
          row2.push(yearBests.paces.map((pace,i) => (
            yearBests.types[i] == 4 ?
              <TableCell key={`year-col-${i}`} align="right" style={{color: color[yearBests.types[i]+1], cursor: 'pointer'}} onClick={ () => onChange(new Date(yearBests.dates[i])) } ><FontAwesomeIcon icon={faTrophy} />{pace}</TableCell>
              :
              yearBests.types[i] == -1 ?
                <TableCell key={`year-col-${i}`} align="right" style={{color: color[yearBests.types[i]+1] }}> --:-- </TableCell>
                  :
                <TableCell key={`year-col-${i}`} align="right" style={{color: color[yearBests.types[i]+1], cursor: 'pointer'}} onClick={ () => onChange(new Date(yearBests.dates[i])) } >{pace}</TableCell>
          )));
        } else {
          row2.push([...Array(17)].map((v,i) => (<TableCell key={`year-col-${i}`} align="right"> --:-- </TableCell>)));
        }
      } else {
        row2.push([...Array(17)].map((v,i) => (<TableCell key={`year-col-${i}`} align="right"> --:-- </TableCell>)));
      }

      if (monthBests !== null) {
        if (monthBests.paces.length > 0) {
          row3.push(monthBests.paces.map((pace,i) => (
            monthBests.types[i] == 4 ?
              <TableCell key={`month-col-${i}`} align="right" style={{color: color[monthBests.types[i]+1], cursor: 'pointer'}} onClick={ () => onChange(new Date(monthBests.dates[i])) } ><FontAwesomeIcon icon={faTrophy} />{pace}</TableCell>
              :
              monthBests.types[i] == -1 ?
                <TableCell key={`month-col-${i}`} align="right" style={{color: color[monthBests.types[i]+1] }}> --:-- </TableCell>
                :
                <TableCell key={`month-col-${i}`} align="right" style={{color: color[monthBests.types[i]+1], cursor: 'pointer'}} onClick={ () => onChange(new Date(monthBests.dates[i])) } >{pace}</TableCell>
          )));
        } else {
          row3.push([...Array(17)].map((v,i) => (<TableCell key={`month-col-${i}`} align="right"> --:-- </TableCell>)));
        }
      } else {
        row3.push([...Array(17)].map((v,i) => (<TableCell key={`month-col-${i}`} align="right"> --:-- </TableCell>)));
      }

      return(
        <ThemeProvider theme={theme}>
        <Box display="block" border={1} borderRadius={5} borderColor="lightgray" >
        <div style={{
                  width: 160,  
                  left: 20,
                  backgroundColor: "#FAFAFA",
                  position: "relative",
                  zIndex: 0
                  }} >
        <Box mt={-2} ml={1} sx={{ flexWrap: 'nowrap' }} >
            <Typography variant="caption" color="inherit" >
            Season Bests
            </Typography>
            <Button 
              onClick={handleBestsOpen}  
              style={{padding: 10, minWidth: 10, minHeight: 10}} >
              <FontAwesomeIcon icon={faChevronUp} style={{color: "gray"}}/>
            </Button>
        </Box>
        </div>
         
        <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell> {distUnit}-paces </TableCell>
                    <TableCell align="right">400m</TableCell>
                    <TableCell align="right">800m</TableCell>
                    <TableCell align="right">1/2mi</TableCell>
                    <TableCell align="right">1000m</TableCell>
                    <TableCell align="right">1500m</TableCell>
                    <TableCell align="right">1mi</TableCell>
                    <TableCell align="right">2km</TableCell>
                    <TableCell align="right">3km</TableCell>
                    <TableCell align="right">2mi</TableCell>
                    <TableCell align="right">5km</TableCell>
                    <TableCell align="right">10km</TableCell>
                    <TableCell align="right">15km</TableCell>
                    <TableCell align="right">10mi</TableCell>
                    <TableCell align="right">20km</TableCell>
                    <TableCell align="right">HM</TableCell>
                    <TableCell align="right">30km</TableCell>
                    <TableCell align="right">M</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {row1}   
                  </TableRow>
                  <TableRow>
                    {row2}
                  </TableRow>
                  <TableRow>
                    {row3}
                  </TableRow>
                </TableBody>
              </Table>
          </TableContainer>            
          </Box>
          </ThemeProvider>
      );
    };

  return (
        <ThemeProvider theme={theme}>
        <Grid item xs={12} style={{marginLeft:5, marginRight:5}} >
        <div>
        {bestsOpen ? displayBests({distUnit,allBests,yearBests,monthBests}) : displayNoBests()}
        </div>
        </Grid>
        </ThemeProvider>
  );  

}


//

class ItemRenderer extends React.Component {
	render() {
    const color = [ "#607d8b", "#e91e63", "#03a9f4", "#9c27b0" ];
		//const item = this.props.data[this.props.index];
		//const style = this.props.style;
		//const runs = item ? item.runs : null
    // new:
    const { data, index, style } = this.props
    const { calItems, initDate, fct } = data
    const item = calItems[index]
    //
		const date = moment(initDate).subtract(this.props.index, 'days').format("ddd, MMM D");
		const num_runs = item ? item.num_runs : ''
    const race_dist = item ? (item.race ? item.race.distance : '') : ''
    const race_time = item ? (item.race ? item.race.time : '') : ''
		const w_stats_dist = item ? (item.w_stats ? item.w_stats.dist : '') : ''
		const w_stats_pace = item ? (item.w_stats ? item.w_stats.pace : '') : ''
		const m_stats_dist = item ? (item.m_stats ? item.m_stats.dist : '') : ''
		const m_stats_pace = item ? (item.m_stats ? item.m_stats.pace : '') : ''
    const vm = item ? (item.analysis ? item.analysis.vm : '') : ''
    const vm_form = item ? (item.analysis ? item.analysis.vm_form : '') : ''
    const tc = item ? (item.analysis ? item.analysis.tc : '') : ''
    const tc_form = item ? (item.analysis ? item.analysis.tc_form : '') : ''
    const Es = item ? (item.analysis ? item.analysis.Es : '') : ''
    const El = item ? (item.analysis ? item.analysis.El : '') : ''
		return (
      <Fragment>
      <ThemeProvider theme={theme}>
      <Grid
        className="List-row"
        style={style}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
      <Grid item xs={2} align="left">

			{/*<table>
			<colgroup>
       			<col span="1" style={{width: 250}} />
       			<col span="1" style={{width: 850}} />
    	</colgroup>
			<thead>
			<tr>
			<td>*/}

			<Typography variant="h6" gutterBottom>
				<Box fontWeight="fontWeightBold">
				{date}
				</Box>
			</Typography>
      {/*<Typography variant="caption" color="secondary">
        Runs: {num_runs}
      </Typography>*/}
				 { m_stats_dist != '' &&  
         <div>
         <Typography variant="caption" color="inherit">
            Month
         </Typography>
				 <Typography variant="body2" gutterBottom>
				 	{m_stats_dist} @ {m_stats_pace} 
				 </Typography>
         </div>
				 }
				 { w_stats_dist != '' &&  
				 	<div>
				 	<Typography variant="caption" color="inherit">
            Week
         </Typography>
         <Typography variant="body2" gutterBottom>
          {w_stats_dist} @ {w_stats_pace} 
         </Typography>
				 	</div>
				 }
      </Grid>

			{/*</td>
			<td>*/}

      <Grid item xs={1} align="left">

      {item ? <div>
      { race_time != '' &&
      <div>
      <Typography style={{color: color[1]}} >
      <FontAwesomeIcon icon={faTrophy} /> {race_dist}
      </Typography>
      <Typography style={{color: color[1]}} >
      {race_time}
      </Typography>
      </div>
      } </div> : <div> </div>} 

      </Grid>

      <Grid item xs={9}>
			{item ? <div>
                <BarGraph data={item} itemIndex={index} fct={fct}/>
                {/*<div style={{display:"flex", alignItems: "center"}}>
                { vm != '' && 
                <div style={{display:"flex", alignItems: "center"}}>
                <Typography  variant="caption" color="secondary" noWrap>
                speed@V02max: 
                </Typography>
                <Typography variant="body2" style={{marginLeft:5}} noWrap>
                {vm_form} ({vm.toFixed(2)} m/s)
                </Typography>
                </div>
                }
                { tc != '' && 
                <div style={{display:"flex", alignItems: "center"}}>
                <Typography  variant="caption" color="secondary" style={{marginLeft:10}} noWrap>
                time@VO2max: 
                </Typography>
                <Typography variant="body2" style={{marginLeft:5}} noWrap>
                {tc_form} ({tc.toFixed(0)} s)
                </Typography>
                </div>
                }
                { Es != '' && 
                <div style={{display:"flex", alignItems: "center"}}>
                <Typography  variant="caption" color="secondary" style={{marginLeft:10}} noWrap>
                Endurance(short): 
                </Typography>
                <Typography variant="body2" style={{marginLeft:5}} noWrap>
                {Es.toFixed(2)}
                </Typography>
                </div>
                }
                { El != '' && 
                <div style={{display:"flex", alignItems: "center"}}>
                <Typography  variant="caption" color="secondary" style={{marginLeft:10}} noWrap>
                Endurance(long): 
                </Typography>
                <Typography variant="body2" style={{marginLeft:5}} noWrap>
                {El.toFixed(2)}
                </Typography>
                </div>
                }
                </div>*/}
              </div>  : <CircularProgress color="inherit"> </CircularProgress>}	
      </Grid>

      {/*</td>
			</tr>
			</thead>
			</table>*/}		

      </Grid>
      </ThemeProvider>
      </Fragment>
		);	
	}
}




  



function YearMonthForm({ startYear, date, initDate, onChange }) {
  const classes = YearMonthFormStyles();
  //const months = localeUtils.getMonths();
  const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 
    'August', 'September', 'October', 'November', 'December'];
  const currentYear = initDate.getFullYear();
  const currentMonth = initDate.getMonth();
  const fromMonth = new Date(startYear, 0);
  const toMonth = new Date(currentYear, currentMonth);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    //console.log(e.target.name + ": " + e.target.value);
    var year  = date.getFullYear();
    var month = date.getMonth();
    if (e.target.name == 'month') { month = e.target.value; }
    if (e.target.name == 'year') { year = e.target.value; }
    //console.log("year: " + year + "month: " + month);
    onChange(moment().year(year).month(month).endOf('month').toDate());
  };

  return (
  	<Fragment>
    <ThemeProvider theme={theme}>
  	<Stack  sx={{ width: 150 }} spacing={2} direction="column">
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="select-year-label">Year</InputLabel>
      <Select
        name="year"
        labelId="select-year-label"
        id = "year"
        value={date.getFullYear()}
        onChange={handleChange}
        label="year"
      >
        {years.map(year => (
              <MenuItem key={year} value={year}>
              {year}
              </MenuItem>
          ))}
      </Select>
    </FormControl>
  	<FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="select-month-label">Month</InputLabel>
      <Select
        name="month"
        labelId="select-month-label"
        id = "month"
        value={date.getMonth()}
        onChange={handleChange}
        label="month"
      >
        {months.map((month,i) => (
              <MenuItem key={month} value={i}>
              {month}
              </MenuItem>
          ))}
      </Select>
    </FormControl>
    </Stack>
    </ThemeProvider>
  	</Fragment>
  );
}



function BarGraph(props) {
  const color = [ "#607d8b", "#e91e63", "#03a9f4", "#9c27b0" ];
  const cutoff = 99.0; // lower cutoff for distane of run
  if (props.data.num_runs === 0) {
  return (
    <FontAwesomeIcon icon={faPauseCircle} size = '2x'/>
  );
  } else {
  const barScale = d => Math.max(0,131.446 * Math.log(d/45.638));
  const opacityScale = p => Math.min(1,0.2 +  Math.max(0,p-0.55) * 1.6); //  0 <= p <=1
  var vm =  props.data.analysis.vm 
  var barGap = 18;
  var barLengths = [];
  var barPositions = [];
  var barOpacity = [];
  var logofRuns = [];
  var overallLength = -barGap; 
  var dayDistance = 0;
  props.data.runs.forEach(run =>
    {
      if ( run.distance > cutoff ) {
        dayDistance += run.distance;
        logofRuns.push(barScale(run.distance));
      }
    }
    )
  var logDaydistance = barScale(dayDistance);
  var logDaydistanceEffetive = logDaydistance - (barGap * (props.data.num_runs-1));
  var totallogofRuns = logofRuns.reduce((a, b) => a + b, 0);
  props.data.runs.forEach( (run,run_index) => 
    {if (run.laps_distance.length == 0 && run.distance > cutoff ) {
      var lapPositions = [overallLength,overallLength+barGap];
      var runBarLength = barScale(run.distance)*logDaydistanceEffetive/totallogofRuns;
      var lapLengths = [barGap,runBarLength]; 
      overallLength += barGap;
      overallLength += runBarLength;
      var lapOpacity = [opacityScale(run.average_speed/vm)];
     } else if ( run.distance > cutoff ) {
     var lapPositions = [overallLength,overallLength+barGap];
     var lapLengths = [barGap];
     var lapOpacity =[];
     overallLength += barGap;
     var runBarLength = barScale(run.distance)*logDaydistanceEffetive/totallogofRuns;
     var logofLaps = run.laps_distance.map(barScale);
     var totallogofLaps = logofLaps.reduce((a, b) => a + b, 0)
    run.laps_distance.forEach( (lap,lap_index) => {
      var bar = barScale(lap)*runBarLength/totallogofLaps; 
      lapLengths.push(Math.max(0,bar-2)); 
      overallLength += bar; 
      lapPositions.push(overallLength);
      var opacity = opacityScale(props.data.computed[run_index].laps_speed[lap_index]/vm);
      lapOpacity.push(opacity);
      }
      )
     }
     if ( run.distance > cutoff ) {
      barPositions.push(lapPositions);
      barLengths.push(lapLengths);
      barOpacity.push(lapOpacity);
     } else {
      barPositions.push([,]);
      barLengths.push(0);
      barOpacity.push(0);
     }
    }
  )
  if ( dayDistance > 0 ) {
  return (
    <svg width={1012} height={100}>
      {props.data.runs.map((d, i) =>  
        d.distance > cutoff ? <BarRun key={`barRun-${i}`} 
            barOpacity={barOpacity[i]} 
            barLengths={barLengths[i]} 
            barPositions={barPositions[i]} 
            data={d} 
            index={i} 
            labels={props.data.labels[i]} 
            itemIndex={props.itemIndex} 
            fct={props.fct} /> : null
      )}
    </svg>
  ); }
  else {
    return (
      <FontAwesomeIcon icon={faPauseCircle} size = '2x'/>
    );
  }
}
}

function HeartIcon(props) {
  const x = props.x;
  const y = props.y;
  const colorId = props.colorId;
  const color = [ "#607d8b", "#e91e63", "#03a9f4", "#9c27b0" ];
  return (
    <g
    transform={`translate(${x.toString()} ${y.toString()}) scale(0.6)`} >
    <path fill={color[colorId]} d="M30.943 8.494c-0.816-2.957-3.098-5.239-5.994-6.040l-0.060-0.014c-0.651-0.159-1.399-0.25-2.169-0.25-2.624 0-5 1.062-6.722 2.779l0-0c-1.558-1.505-3.682-2.433-6.023-2.433-0.77 0-1.516 0.1-2.226 0.288l0.060-0.014c-3.104 0.882-5.499 3.277-6.365 6.317l-0.016 0.065c-0.171 0.648-0.269 1.393-0.269 2.16 0 2.588 1.117 4.915 2.896 6.525l0.008 0.007 11.381 12.619c0.138 0.153 0.336 0.248 0.557 0.248s0.419-0.095 0.556-0.247l0.001-0.001 11.369-12.605c2.002-1.789 3.256-4.379 3.256-7.261 0-0.759-0.087-1.498-0.252-2.208l0.013 0.066zM26.85 16.851l-0.025 0.028-10.824 12.002-10.851-12.030c-1.526-1.359-2.483-3.329-2.483-5.523 0-0.621 0.077-1.224 0.221-1.8l-0.011 0.051c0.736-2.588 2.733-4.585 5.267-5.307l0.054-0.013c0.53-0.149 1.138-0.235 1.766-0.236h0.001c2.18 0.065 4.126 1.015 5.5 2.503l0.005 0.005c0.138 0.131 0.325 0.211 0.53 0.211s0.392-0.080 0.531-0.211l-0 0c1.507-1.742 3.722-2.838 6.192-2.838 0.63 0 1.244 0.071 1.833 0.206l-0.055-0.011c2.439 0.674 4.321 2.555 4.982 4.944l0.012 0.050c0.124 0.534 0.195 1.147 0.195 1.776 0 2.471-1.097 4.685-2.83 6.184l-0.010 0.009z"/>
    </g>
    );
}

function ECGIcon(props) {
  const x = props.x;
  const y = props.y;
  const colorId = props.colorId;
  const color = [ "#607d8b", "#e91e63", "#03a9f4", "#9c27b0" ];
  return (
    <g
    transform={`translate(${x.toString()} ${y.toString()}) scale(0.6)`} >
    <path fill={color[colorId]} d="M30.943 8.494c-0.816-2.957-3.098-5.239-5.994-6.040l-0.060-0.014c-0.651-0.159-1.399-0.25-2.169-0.25-2.624 0-5 1.062-6.722 2.779l0-0c-1.558-1.505-3.682-2.433-6.023-2.433-0.77 0-1.516 0.1-2.226 0.288l0.060-0.014c-3.104 0.882-5.499 3.277-6.365 6.317l-0.016 0.065c-0.171 0.648-0.269 1.393-0.269 2.16 0 2.588 1.117 4.915 2.896 6.525l0.008 0.007 11.381 12.619c0.138 0.153 0.336 0.248 0.557 0.248s0.419-0.095 0.556-0.247l0.001-0.001 11.369-12.605c2.002-1.789 3.256-4.379 3.256-7.261 0-0.759-0.087-1.498-0.252-2.208l0.013 0.066zM26.824 16.879l-10.824 12.002-10.851-12.030c-0.033-0.033-0.058-0.068-0.090-0.101h2.941c0.277-0 0.52-0.151 0.649-0.374l0.002-0.004 3.116-5.454 3.512 12.287c0.089 0.309 0.364 0.533 0.693 0.545l0.001 0h0.026c0.322-0 0.597-0.204 0.703-0.489l0.002-0.005 3.404-9.361 1.221 2.44c0.125 0.248 0.378 0.415 0.67 0.415h4.941c-0.032 0.033-0.058 0.068-0.091 0.101zM28.18 15.25h-5.718l-1.793-3.585c-0.125-0.248-0.379-0.415-0.671-0.415-0.322 0-0.597 0.204-0.703 0.489l-0.002 0.005-3.209 8.826-3.365-11.776c-0.093-0.316-0.38-0.544-0.721-0.544-0.277 0-0.519 0.15-0.649 0.374l-0.002 0.004-3.784 6.622h-3.712c-0.754-1.093-1.204-2.447-1.204-3.905 0-0.628 0.083-1.236 0.24-1.814l-0.011 0.049c0.736-2.588 2.733-4.585 5.267-5.307l0.054-0.013c0.53-0.149 1.138-0.235 1.766-0.236h0.001c2.18 0.065 4.126 1.015 5.5 2.503l0.005 0.005c0.138 0.131 0.325 0.211 0.53 0.211s0.392-0.080 0.531-0.211l-0 0c1.507-1.742 3.722-2.838 6.192-2.838 0.63 0 1.244 0.071 1.833 0.206l-0.055-0.011c2.439 0.674 4.321 2.555 4.982 4.944l0.012 0.050c0.139 0.543 0.22 1.166 0.22 1.807 0 1.722-0.576 3.309-1.547 4.579l0.013-0.018z"/>
    </g>
    );
}

function PowerIcon(props) {
  const x = props.x;
  const y = props.y;
  const colorId = props.colorId;
  const color = [ "#607d8b", "#e91e63", "#03a9f4", "#9c27b0" ];
  return (
    <g
    transform={`translate(${x.toString()} ${y.toString()}) scale(0.01875)`} >
    <path d="M510.5 958.5c247.2 0 447.5-200.4 447.5-447.5S757.7 63.5 510.5 63.5 63 263.8 63 511s200.4 447.5 447.5 447.5z" fill={color[colorId]} />
    <path d="M510.5 902.6c-216.3 0-391.6-175.3-391.6-391.6S294.2 119.4 510.5 119.4s391.6 175.3 391.6 391.6-175.3 391.6-391.6 391.6z" fill="#FFFFFF" />
    <path d="M405.8 558.9h-2c-31 0-43-21.4-27-47.8L524 269.2c33.5-55.1 44.2-50.2 24 11l-45.1 136.1c-9.7 29.4 7.5 52.9 38.4 52.9H628l-0.4 0.4h2c31 0 43 21.4 27 47.8L515.7 749c-36.9 60.7-48.8 55.3-26.4-12.3l41.3-124.5c9.7-29.4-7.5-52.9-38.4-52.9h-86.7l0.3-0.4z" fill={color[colorId]} />
    </g>
    );
}


function BarRun(props) {
  const margin = { top: 25, bottom: 0, left: 50, right: 0 };  
  const color = [ "#607d8b", "#e91e63", "#03a9f4", "#9c27b0" ];
  const barLengths = props.barLengths;
  const barPositions = props.barPositions;
  const barOpacity = props.barOpacity;
  
  const lapFill = barOpacity.map(op => 
    op < 0.5 ? color[props.data.workout_type_id] : "White");

  var xPosName = barPositions[1] + 2;
  if ( props.data.has_heartrate === true ) {
    xPosName += 20;
  }
  if ( props.data.has_power === true ) {
    xPosName += 20;
  }

  //
  //console.log("index: " + props.index );
  //console.log("barOpacity: " + barOpacity);
  //console.log("lapFill: " + lapFill);
  //const xMax = 300 - margin.left - margin.right;
  //const x = d => d.distance;
  //const xScale = scaleLog({
  // range: [0, xMax],
  //  round: true,
  //  domain: [1, 50000], // 50km is max
  //});
  //const compose = (scale, accessor) => data => scale(accessor(data));
  //const xPoint = compose(xScale, x);
  if (barLengths.length == 2) {
    return (
      <Group key={`bar-${props.index}`}>
      <Bar
              y={margin.top}
              x={barPositions[1]}
              width={barLengths[1]}
              height={25}
              fill={color[props.data.workout_type_id]}
              style={{ opacity: `${barOpacity[0]}` }}
            />
      <Text
        x={barPositions[1]+barLengths[1]}
        y={margin.top-2}
        width={barLengths[1]}
        verticalAnchor="end"
        textAnchor="end"
        fontFamily={"Open Sans"}
        fill={color[props.data.workout_type_id]}
        style={{ fontSize: '15px' }}
        >
        {props.labels.distance_formated}
      </Text>
      <Text
        x={barPositions[1]+5}
        y={margin.top + 7}
        width={barLengths[1]-20}
        verticalAnchor="start"
        fontFamily={"Open Sans"}
        fill={`${lapFill[0]}`}
        style={{ fontSize: '12px' }}
        >
        {props.labels.pace_formated}
      </Text>
      {props.data.has_power ? <PowerIcon x={barPositions[1]} y={margin.top + 26} colorId={props.data.workout_type_id} /> : null}
      {props.data.has_heartrate ? 
        props.data.has_rr ? <ECGIcon x={xPosName-20} y={margin.top + 26} colorId={props.data.workout_type_id} /> : <HeartIcon x={xPosName-20} y={margin.top + 26} colorId={props.data.workout_type_id} />  : null}
      <Text
        x={xPosName}
        y={margin.top + 29}
        width={barLengths[1]-20}
        verticalAnchor="start"
        fontFamily={"Open Sans"}
        fill={color[props.data.workout_type_id]}
        style={{ fontSize: '14px' , cursor: 'pointer' }}
        onClick={ () => props.fct(props.itemIndex,props.index)}
        //onClick={ () => onClickActivity(props.data.id,props.data.name,props.data.workout_type_id) }
        >
        {props.data.name}
      </Text>
      </Group>
    );
  } else {
    return (
      <Group key={`bar-${props.index}`}>
      <Text
        x={barPositions[barPositions.length-1]+6}
        y={margin.top+2}
        width={12}
        verticalAnchor="end"
        textAnchor="start"
        angle={330}
        fontFamily={"Open Sans"}
        fill={color[props.data.workout_type_id]}
        style={{ fontSize: '14px' }}
        >
        {props.labels.distance_formated}
      </Text>
      {props.data.has_power ? <PowerIcon x={barPositions[1]} y={margin.top + 26} colorId={props.data.workout_type_id} /> : null}
      {props.data.has_heartrate ? 
      props.data.has_rr ? <ECGIcon x={xPosName-20} y={margin.top + 26} colorId={props.data.workout_type_id} /> : <HeartIcon x={xPosName-20} y={margin.top + 26} colorId={props.data.workout_type_id} />  : null}
       <Text
        x={xPosName}
        y={margin.top + 29}
        width={barPositions[barPositions.length-1]-barPositions[1]}
        verticalAnchor="start"
        fontFamily={"Open Sans"}
        fill={color[props.data.workout_type_id]}
        style={{ fontSize: '14px', cursor: 'pointer' }}
        onClick={ () => props.fct(props.itemIndex,props.index)}
        //
        //onClick={ () => onClickActivity(props.data.id,props.data.name,props.data.workout_type_id) }
        //onClick={ event => window.open(`https://connect.garmin.com/modern/activity/${props.data.external_id}`, '_blank')}
        >
        {props.data.name}
      </Text>
      {props.data.laps_distance.map((lap,j) =>            
          <Group key={`bar-${props.index}-${j}`}>
          <Bar
            y={margin.top}
            x={barPositions[j+1]}
            width={barLengths[j+1]}
            height={25}
            fill={color[props.data.workout_type_id]}
            style={{ opacity: `${barOpacity[j]}` }}
          />
          {barLengths[j+1] > 12? <Text 
            x={ j==props.data.laps_distance.length-1 ? (Math.min( (barPositions[j+1] + barLengths[j+1]/2), barPositions[barPositions.length-1]-12 )):(barPositions[j+1] + barLengths[j+1]/2) } 
            y={margin.top}
            width={12}
            verticalAnchor="end"
            textAnchor="start"
            angle={330}
            fontFamily={"Open Sans"}
            style={{ fontSize: '10px' }}
            fill={color[props.data.workout_type_id]}
          >
          {props.labels.laps_dist_formated[j]}
          </Text>:''} 
         {barLengths[j+1] > 12?  <Text
            x={barPositions[j+1] + 3}
            y={margin.top + 2}
            width={20}
            verticalAnchor="start"
            textAnchor="end"
            fontFamily={"Open Sans"}
            fill={`${lapFill[j]}`}
            style={{ fontSize: '8px' }}
            angle={270}
            scaleToFit
            >
          {props.labels.laps_time_formated[j]}
          </Text>:''}
          </Group>
        )}
      </Group>
    );
}
}

// Hook

function useWindowSize() {

  // Initialize state with undefined width/height so server and client renders match

  const [windowSize, setWindowSize] = useState({

    width: undefined,

    height: undefined,

  });



  useEffect(() => {

    // Handler to call on window resize

    function handleResize() {

      // Set window width/height to state

      setWindowSize({

        width: window.innerWidth,

        height: window.innerHeight,

      });

    }

    

    // Add event listener

    window.addEventListener("resize", handleResize);

    

    // Call handler right away so state gets updated with initial window size

    handleResize();

    

    // Remove event listener on cleanup

    return () => window.removeEventListener("resize", handleResize);

  }, []); // Empty array ensures that effect is only run on mount



  return windowSize;

}


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});


const YearMonthFormStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
const StyledButton = withStyles({
  root: {
    background: 'orange',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const StyledDivider = withStyles({
	root: {
		color: '#ECECEC'
	}
})(Divider);

// new

// CalendarApp.propTypes = {
//   classes: PropTypes.object.isRequired,
// }

export default CalendarApp;

